import React, { useState, useEffect } from "react";
import { Form, Input, Select, Col, Row, Switch , Alert} from "antd";
import { useHistory } from "react-router-dom";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";
import ImageField from "../Core/ImageField";

import TextArea from "antd/lib/input/TextArea";
import DashboardActionBar from "../DashboardActionBar";

const { Option } = Select;

const AuthorForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [description, setDescription] = useState(
    isUpdateForm && data.dataById?.data.description
  );
  const [avatar, setAvatar] = useState(
    isUpdateForm
      ? data.dataById &&
          data.dataById.data.avatar &&
          data.dataById.data.avatar.full_path
      : null
  );
  const [image_crop_open, setImageCropOpen] = useState(false);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading; // || data.loading

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
  }, []);

  const authorAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const axios = require("axios");
      values.description = description;
      values.status = values.status ? 1 : 0;

      if (action === "add") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity(
              {
                ...values,
                avatar: response.data,
              },
              tokenData
            );
          });
        } else {
          createEntity(
            {
              ...values,
            },
            tokenData
          );
        }
      } else if (action === "addandexit") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity(
              {
                ...values,
                avatar: response.data,
              },
              tokenData,
              true,
              history
            );
          });
        } else {
          createEntity(
            {
              ...values,
            },
            tokenData,
            true,
            history
          );
        }
      } else if (action === "update") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            updateEntityInfo(
              {
                ...values,
                avatar: response.data,
              },
              tokenData,
              params.id
            );
          });
        } else {
          updateEntityInfo(
            {
              ...values,
            },
            tokenData,
            params.id
          );
        }
      }
    });
  };

  const addNewEntry = (e) => {
    authorAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    authorAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    authorAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setDescription("");
    setAvatar("");
  };

  if (isUpdateForm) {
    setFieldsValue({
      name: data.dataById?.data.name,
      url_title: data.dataById?.data.urlinfo?.url_title,
      slug: data.dataById?.data.urlinfo?.url_slug,
      salutation: data.dataById?.data.salutation,
      email: data.dataById?.data.email,
      status: data.dataById?.data.status == 1 ? true : false,
      is_featured: data.dataById?.data.is_featured == 1 ? true : false,
      description: data.dataById?.data.description,
      meta_title: data.dataById?.data.meta?.meta_title,
      meta_keywords: data.dataById?.data.meta?.meta_keywords,
      meta_description: data.dataById?.data.meta?.meta_description,
      avatar: data.dataById?.data.avatar?.thumb_path,
      avatar_alt: data.dataById?.data.avatar?.alt_text,
      avatar_caption: data.dataById?.data.avatar?.caption,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Author",
    titleToUpdate: "Update Author",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Author",
    taskCompleteAddMessage: "Author is added!",
    taskCompleteUpdateMessage: "Author is updated!",
    discardLink: "blog/authors",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <div className="common-module mt-3">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Row gutter={{ xs: 2.5, sm: 2.5, md: 2.5 }}>
                <Col className="gutter-row" xs={6}>
                  <Form.Item
                    label="Full Name"
                    name="salutation"
                    rules={[
                      {
                        required: true,
                        message: "Salutation is required",
                      },
                    ]}
                  >
                    <Select>
                      <Option value="Mr.">Mr.</Option>
                      <Option value="Ms.">Ms.</Option>
                      <Option value="Mrs.">Mrs.</Option>
                      <Option value="Miss.">Miss.</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={18}>
                  <Form.Item
                    label="&nbsp;"
                    name="name"
                    className="noStar"
                    rules={[
                      {
                        required: true,
                        message: "Full name is required",
                      },
                      { min: 5, max: 255, message: "Invalid title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Url Title"
                name="url_title"
                rules={[
                  {
                    required: true,
                    message: "Url title is required",
                  },
                  { min: 5, max: 255, message: "Invalid Url title" },
                ]}
              >
                <Input
                  onChange={(e) =>
                    form.setFieldsValue({ slug: slugify(e.target.value) })
                  }
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label={
                  <span className="ant-form-item-optional">
                    Auto Generate from Url Title
                  </span>
                }
                name="slug"
                title="Auto Generate from Url Title"
                rules={[
                  {
                    required: true,
                    message: "Slug is required",
                  },
                ]}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={16}>
              <div className="switch-group">
                <Form.Item label="Status" valuePropName="checked" name="status">
                  <Switch
                    checkedChildren="Publish"
                    unCheckedChildren="Unpublish"
                  />
                </Form.Item>
              </div>
            </Col>

            <Col className="gutter-row" xs={24} xl={16}>
              <Form.Item label="Description" name="description">
                <TextEditor
                  value={description}
                  initialValue={isUpdateForm && description}
                  onEditorChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={8}>
              <label className="form-label">Meta</label>
              <div className="card bg-white mb-4 mb-xl-0">
                <div className="card-body">
                  <Form.Item
                    label="Meta Title"
                    name="meta_title"
                    rules={[
                      {
                        required: true,
                        message: "Meta title is required",
                      },
                      { min: 5, max: 255, message: "Invalid meta title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Meta Keywords"
                    name="meta_keywords"
                    rules={[
                      {
                        min: 5,
                        max: 255,
                        message: "Invalid meta keywords",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    className="mb-0"
                    label="Meta Description"
                    name="meta_review"
                  >
                    <TextArea rows={8} />
                  </Form.Item>
                </div>
              </div>
            </Col>

            <Col className="gutter-row" xs={24} xl={10}>
              <div className="card bg-white mb-4 mb-xl-0">
                <div className="card-body">
                  <Form.Item>
                    <ImageField
                      title="Avatar"
                      image={
                        avatar ||
                        (isUpdateForm && data.dataById?.data.avatar?.full_path)
                      }
                      height="300"
                      width="400"
                      setImage={(image_data) => {
                        setAvatar(image_data);
                        setImageCropOpen(true);
                      }}
                    />
                    {avatar && image_crop_open && (
                      <ImageCrop
                        image={avatar}
                        setCroppedImage={setAvatar}
                        setImageCropOpen={setImageCropOpen}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: "-20px" }}
                    label="Alt Text"
                    name="avatar_alt"
                    rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Caption"
                    name="avatar_caption"
                    rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default AuthorForm;
