import {
  BLOG_LIST_LOADING,
  GET_BLOG_LIST_ERROR,
  GET_BLOG_LIST,
  GET_BLOG_BY_ID_LOADING,
  GET_BLOG_BY_ID_SUCCESS,
  GET_BLOG_BY_ID_ERROR,
  DELETE_BLOG,
  DELETE_BLOG_ERROR,
  DELETEING_BLOG,
  DELETED_BLOG_LIST_LOADING,
  GET_DELETED_BLOG_LIST,
  GET_DELETED_BLOG_LIST_ERROR,
  RESTORE_BLOG_LIST_LOADING,
  RESTORE_BLOG_LIST,
  RESTORE_BLOG_LIST_ERROR,
  PERMA_DELETE_BLOG,
  PERMA_DELETE_BLOG_ERROR,
  PERMA_DELETEING_BLOG,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BLOG_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_BLOG_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_BLOG_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_BLOG:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case RESTORE_BLOG_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case PERMA_DELETEING_BLOG:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_BLOG_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_BLOG_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };

    case DELETE_BLOG:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_DELETED_BLOG_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case RESTORE_BLOG_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case PERMA_DELETE_BLOG:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_BLOG_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_BLOG_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DELETED_BLOG_LIST_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_BLOG_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case RESTORE_BLOG_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case PERMA_DELETE_BLOG_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
