export const CATEGORY_TYPE_LIST_LOADING = "CATEGORY_TYPE_LIST_LOADING";
export const GET_CATEGORY_TYPE_LIST = "GET_CATEGORY_TYPE_LIST";
export const GET_CATEGORY_TYPE_LIST_ERROR = "GET_CATEGORY_TYPE_LIST_ERROR";
export const GET_CATEGORY_TYPE_BY_ID_LOADING = "GET_CATEGORY_TYPE_BY_ID_LOADING";
export const GET_CATEGORY_TYPE_BY_ID_SUCCESS = "GET_CATEGORY_TYPE_BY_ID_SUCCESS";
export const GET_CATEGORY_TYPE_BY_ID_ERROR = "GET_CATEGORY_TYPE_BY_ID_ERROR";

export const DELETEING_CATEGORY_TYPE = "DELETEING_CATEGORY_TYPE";
export const DELETE_CATEGORY_TYPE = "DELETE_CATEGORY_TYPE";
export const DELETE_CATEGORY_TYPE_ERROR = "DELETE_CATEGORY_TYPE_ERROR" 

export const DELETED_CATEGORY_TYPE_LIST_LOADING = "DELETED_CATEGORY_TYPE_LIST_LOADING";
export const GET_DELETED_CATEGORY_TYPE_LIST = "GET_DELETED_CATEGORY_TYPE_LIST";
export const GET_DELETED_CATEGORY_TYPE_LIST_ERROR = "GET_DELETED_CATEGORY_TYPE_LIST_ERROR";

export const RESTORE_CATEGORY_TYPE_LIST_LOADING = "RESTORE_CATEGORY_TYPE_LIST_LOADING";
export const RESTORE_CATEGORY_TYPE_LIST = "RESTORE_CATEGORY_TYPE_LIST";
export const RESTORE_CATEGORY_TYPE_LIST_ERROR = "RESTORE_CATEGORY_TYPE_LIST_ERROR";

export const PERMA_DELETEING_CATEGORY_TYPE = "PERMA_DELETEING_CATEGORY_TYPE";
export const PERMA_DELETE_CATEGORY_TYPE = "PERMA_DELETE_CATEGORY_TYPE";
export const PERMA_DELETE_CATEGORY_TYPE_ERROR = "PERMA_DELETE_CATEGORY_TYPE_ERROR" 
