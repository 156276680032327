import {
  CREATING_TEAM,
  UPDATING_TEAM,
  TEAM_CREATED,
  TEAM_UPDATED,
  ERROR_TEAM_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
}

export const createEntity = (formData, tokenData, exitNow, history) => async (dispatch) => {
  dispatch({
    type: CREATING_TEAM,
  });
  let data = Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != null).filter(([_, v]) => v !== "").filter(([_, v]) => v !== undefined));
  let webKitFormData = new FormData();

  if(data.categories){ 
    for(let i = 0; i < data.categories.length; i++) {
      webKitFormData.append(`categories[${i}]`, data.categories[i])
    }
  }

  ['categories'].forEach(e => delete data[e]);

  Object.keys(data).forEach((item, i) => {
    webKitFormData.append(item, data[item]) 
  });

  await axios
    .post(`${BASE_URL}api/v1/admin/team/member/add`, webKitFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: TEAM_CREATED,
        payload: response,
      });
      dispatch({
        type: UPDATED_RECENTLY,
      })      
      message.success(response.data.message);
      if (exitNow) {
        history.push("/teams")
      }
    })
    .catch(function (error) {
      dispatch({
        type: ERROR_TEAM_CREATE,
        payload: error.response,
      })      
      message.error(error.response.data.message);
    });
};

export const updateEntityInfo = (formData, tokenData, id) => async (dispatch) => {
  dispatch({
    type: UPDATING_TEAM,
  });
  
  let data = Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != null).filter(([_, v]) => v !== "").filter(([_, v]) => v !== undefined));

  let webKitFormData = new FormData();
  if (data.avatar && (typeof data.avatar === 'string')) {
    delete data.avatar
  } else if (data.avatar && (typeof data.avatar === 'object')) {
    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item])
    });
    webKitFormData.append('_method', 'put')
  }
  

  await axios
    .post(`${BASE_URL}api/v1/admin/team/member/edit/${id}`, typeof formData.avatar === 'object' ? webKitFormData : {...formData, "_method": 'put'} , {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: TEAM_UPDATED,
        payload: response,
      });
      dispatch({
        type: UPDATED_RECENTLY,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: ERROR_TEAM_CREATE,
        payload: error.response,
      })  
      message.error(error.response.data.message);
    });
};
