export const RoundedSubmitButton = (props) => {
  return(
  <div className="submit-btn">
    <button
      type="submit"
      className="btn btn-primary"
      {...props}
    >
      {props.loading && (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}
      {!props.loading && <span>{props.children}</span>}
    </button>
  </div>)
}

export const Button = (props) => {
  return(
    <button
      {...props}
      className="btn btn-primary"
    >
      <span>{props.children}</span>
    </button>
  )
}

export default Button
