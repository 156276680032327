import {
  ITINERARY_LIST_LOADING,
  GET_ITINERARY_LIST_ERROR,
  GET_ITINERARY_LIST,
  GET_ITINERARY_BY_ID_LOADING,
  GET_ITINERARY_BY_ID_SUCCESS,
  GET_ITINERARY_BY_ID_ERROR,
  DELETE_ITINERARY,
  DELETE_ITINERARY_ERROR,
  DELETEING_ITINERARY,
  DELETED_ITINERARY_LIST_LOADING,
  GET_DELETED_ITINERARY_LIST,
  GET_DELETED_ITINERARY_LIST_ERROR,
  RESTORE_ITINERARY_LIST_LOADING,
  RESTORE_ITINERARY_LIST,
  RESTORE_ITINERARY_LIST_ERROR,
  PERMA_DELETE_ITINERARY,
  PERMA_DELETE_ITINERARY_ERROR,
  PERMA_DELETEING_ITINERARY,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ITINERARY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_ITINERARY_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_ITINERARY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_ITINERARY:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case RESTORE_ITINERARY_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case PERMA_DELETEING_ITINERARY:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_ITINERARY_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_ITINERARY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };

    case DELETE_ITINERARY:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_DELETED_ITINERARY_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case RESTORE_ITINERARY_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case PERMA_DELETE_ITINERARY:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_ITINERARY_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_ITINERARY_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DELETED_ITINERARY_LIST_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_ITINERARY_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case RESTORE_ITINERARY_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case PERMA_DELETE_ITINERARY_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
