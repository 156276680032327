import React, { useState } from "react";
import DashboardLayout from "../Components/Layouts/DashboardLayout";
import RecentUpdates from "../Components/RecentUpdates";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import ApexCharts from "apexcharts";

var colors = {
  primary: "#0091ff",
  secondary: "#7987a1",
  success: "#42b72a",
  info: "#68afff",
  warning: "#fbbc06",
  danger: "#ff3366",
  light: "#ececec",
  dark: "#282f3a",
  muted: "#686868",
};

const SwiperPagination = () => {
  return <div className="swiper-pagination"></div>;
};

const ContentInsight = () => {
  return (
    <Swiper
      spaceBetween={24}
      slidesPerView={2}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      breakpoints={{
        320: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1400: {
          slidesPerView: 4,
        },
      }}
    >
      <SwiperSlide>
        <div className="swiper-slide">
          <div className="card module bg-light-success">
            <div className="card-body">
              <div className="icon">
                <i className="bi bi-map"></i>
              </div>
              <div className="body-text-left">
                <span className="badge">25</span>
                <h2 className="text-white">
                  <a href="#package-listing-page">Packages</a>
                </h2>
                <ul className="meta">
                  <li>
                    <a href="#package-listing-page/unpublished-packages">
                      Unpublished <span className="badge">2</span>
                    </a>
                  </li>
                </ul>

                <div className="date">
                  <i className="bi bi-clock"></i>Last Updated on 10 Jan 2021
                </div>
              </div>

              <div className="body-text-right">
                <a
                  href="#"
                  className="btn-add-new"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Add New Package"
                >
                  <i className="bi bi-plus"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-slide">
          <div className="card module bg-light-warning">
            <div className="card-body">
              <div className="icon">
                <i className="bi bi-diagram-3"></i>
              </div>
              <div className="body-text-left">
                <span className="badge">21</span>
                <h2 className="text-white">
                  <a href="#package-listing-page">Categories</a>
                </h2>
                <ul className="meta">
                  <li>
                    <a href="#package-listing-page/unpublished-packages">
                      Unpublished <span className="badge">2</span>
                    </a>
                  </li>
                </ul>

                <div className="date">
                  <i className="bi bi-clock"></i>Last Updated on 10 Jan 2021
                </div>
              </div>

              <div className="body-text-right">
                <a
                  href="#"
                  className="btn-add-new"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Add New Category"
                >
                  <i className="bi bi-plus"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-slide">
          <div className="card module bg-light-primary">
            <div className="card-body">
              <div className="icon">
                <i className="bi bi-file-earmark-text"></i>
              </div>
              <div className="body-text-left">
                <span className="badge">15</span>
                <h2 className="text-white">
                  <a href="#package-listing-page">Articles</a>
                </h2>
                <ul className="meta">
                  <li>
                    <a href="#package-listing-page/unpublished-packages">
                      Unpublished <span className="badge">2</span>
                    </a>
                  </li>
                </ul>

                <div className="date">
                  <i className="bi bi-clock"></i>Last Updated on 10 Jan 2021
                </div>
              </div>

              <div className="body-text-right">
                <a
                  href="#"
                  className="btn-add-new"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Add New Articles"
                >
                  <i className="bi bi-plus"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-slide">
          <div className="card module bg-light-danger">
            <div className="card-body">
              <div className="icon">
                <i className="bi bi-file-earmark-richtext"></i>
              </div>
              <div className="body-text-left">
                <span className="badge">25</span>
                <h2 className="text-white">
                  <a href="#package-listing-page">Blogs</a>
                </h2>
                <ul className="meta">
                  <li>
                    <a href="#package-listing-page/unpublished-packages">
                      Unpublished <span className="badge">2</span>
                    </a>
                  </li>
                </ul>

                <div className="date">
                  <i className="bi bi-clock"></i>Last Updated on 10 Jan 2021
                </div>
              </div>

              <div className="body-text-right">
                <a
                  href="#"
                  className="btn-add-new"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Add New Blog"
                >
                  <i className="bi bi-plus"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

class InsightCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    const { containerId, options } = this.props;
    const apexChart1 = document.querySelector(`#${containerId}`);
    if (apexChart1) {
      new ApexCharts(
        document.querySelector(`#${containerId}`),
        options
      ).render();
    }
  }

  render() {
    const { data, idx, open_idx, openDropDown, containerId } = this.props;
    return (
      <div className="card bg-white">
        <div className="card-header">
          <h3 className="card-title">{data.title}</h3>
          <div className="dropdown">
            <a
              href="javascript:"
              id="dropdownMenuButton"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => {
                openDropDown(idx);
              }}
            >
              <i className="bi bi-three-dots"></i>
            </a>
            <div
              className={`dropdown-menu dropdown-menu-end ${
                open_idx === idx ? "show" : ""
              }`}
              aria-labelledby="dropdownMenuButton"
            >
              <a className="dropdown-item" href="#">
                <i className="bi bi-eye"></i>
                <span>View</span>
              </a>
              <a className="dropdown-item" href="#">
                <i className="cbi bi-pencil-square"></i>
                <span>Edit</span>
              </a>
              <a className="dropdown-item" href="#">
                <i className="bi bi-trash"></i>
                <span>Delete</span>
              </a>
              <a className="dropdown-item" href="#">
                <i className="bi bi-printer"></i>
                <span>Print</span>
              </a>
              <a className="dropdown-item" href="#">
                <i className="bi bi-download"></i>
                <span>Download</span>
              </a>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <h3 className="mb-2">{data.value}</h3>
              <div className="d-flex align-items-baseline">
                <p
                  className={
                    data.direction === "up" ? "text-success" : "text-danger"
                  }
                >
                  <span>{data.percentage}</span>
                  {data.direction === "up" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-arrow-up icon-sm mb-1"
                    >
                      <line x1="12" y1="19" x2="12" y2="5"></line>
                      <polyline points="5 12 12 5 19 12"></polyline>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-arrow-down icon-sm mb-1"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <polyline points="19 12 12 19 5 12"></polyline>
                    </svg>
                  )}
                </p>
              </div>
            </div>
            <div className="col-6">
              <div id={containerId}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Insight = () => {
  const data = [
    {
      title: "Visitors",
      value: "3,897",
      percentage: "+3.3%",
      direction: "up",
    },
    {
      title: "Booking",
      value: "35,084",
      percentage: "-2.8%",
      direction: "down",
    },
    { title: "Growth", value: "89.87%", percentage: "+2.8%", direction: "up" },
  ];
  const options = {
    0: {
      chart: {
        type: "line",
        height: 60,
        sparkline: {
          enabled: !0,
        },
      },
      series: [
        {
          data: [
            3844, 3855, 3841, 3867, 3822, 3843, 3821, 3841, 3856, 3827, 3843,
          ],
        },
      ],
      stroke: {
        width: 2,
        curve: "smooth",
      },
      markers: {
        size: 0,
      },
      colors: [colors.primary],
      tooltip: {
        fixed: {
          enabled: !1,
        },
        x: {
          show: !1,
        },
        y: {
          title: {
            formatter: function (e) {
              return "";
            },
          },
        },
        marker: {
          show: !1,
        },
      },
    },
    1: {
      chart: {
        type: "bar",
        height: 60,
        sparkline: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
        },
      },
      colors: [colors.primary],
      series: [
        {
          data: [36, 77, 52, 90, 74, 35, 55, 23, 47, 10, 63],
        },
      ],
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      xaxis: {
        crosshairs: {
          width: 1,
        },
      },
      tooltip: {
        fixed: {
          enabled: !1,
        },
        x: {
          show: !1,
        },
        y: {
          title: {
            formatter: function (e) {
              return "";
            },
          },
        },
        marker: {
          show: !1,
        },
      },
    },
    2: {
      chart: {
        type: "line",
        height: 60,
        sparkline: {
          enabled: !0,
        },
      },
      series: [
        {
          data: [41, 45, 44, 46, 52, 54, 43, 74, 82, 82, 89],
        },
      ],
      stroke: {
        width: 2,
        curve: "smooth",
      },
      markers: {
        size: 0,
      },
      colors: [colors.primary],
      tooltip: {
        fixed: {
          enabled: !1,
        },
        x: {
          show: !1,
        },
        y: {
          title: {
            formatter: function (e) {
              return "";
            },
          },
        },
        marker: {
          show: !1,
        },
      },
    },
  };
  let [open_idx, setOpenIdx] = React.useState(-1);
  const openDropDown = (val) => {
    if (val === open_idx) {
      setOpenIdx(-1);
    } else {
      setOpenIdx(val);
    }
  };
  return (
    <section className="common-module">
      <div className="row">
        {data.map((cardData, idx) => {
          return (
            <div className="col-lg-4" key={idx}>
              <InsightCard
                data={cardData}
                idx={idx}
                options={options[idx]}
                open_idx={open_idx}
                openDropDown={openDropDown}
                containerId={`apexchart${idx}`}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

const SocialMedia = () => {
  return (
    <div className="social-info">
      <ul>
        <li>
          <div className="card bg-light-facebook">
            <i className="bi bi-facebook"></i> <h5>Fcebook</h5>
            <span className="meta">1910 Liks</span>
          </div>
        </li>
        <li>
          <div className="card bg-light-twitter">
            <i className="bi bi-twitter"></i>
            <h5>Twitter</h5>
            <span className="meta">15,000 Tweets</span>
          </div>
        </li>
        <li>
          <div className="card bg-light-instagram">
            <i className="bi bi-instagram"></i>
            <h5>Instagram</h5>
            <spam className="meta">9000 Followers</spam>
          </div>
        </li>
        <li>
          <div className="card bg-light-linkedin">
            <i className="bi bi-linkedin"></i>
            <h5>Linkedin</h5>
            <span className="meta">7500 Followers</span>
          </div>
        </li>
      </ul>
    </div>
  );
};

class RTVisitorChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    const { containerId, options } = this.props;
    const apexChart1 = document.querySelector(`#${containerId}`);
    if (apexChart1) {
      new ApexCharts(
        document.querySelector(`#${containerId}`),
        options
      ).render();
    }
  }

  render() {
    const { data, idx, open_idx, openDropDown, containerId } = this.props;
    return <div id={containerId}></div>;
  }
}

const RealtimeVisitor = () => {
  const options = {
    series: [44, 55, 13, 33],
    chart: {
      width: 450,
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Chrome", "Safari", "Firefox", "Edge", "others"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            show: true,
          },
        },
      },
    ],
    legend: {
      position: "bottom",
      offsetY: 0,
      height: 50,
    },
  };
  return (
    <div className="card bg-white">
      <div className="card-header">
        <div className="card-title">
          <h3>Real time visitors</h3>
          <p className="lead">25 Active Users</p>
        </div>
      </div>
      <div className="card-body">
        <RTVisitorChart containerId="apexChart4" options={options} />
      </div>
    </div>
  );
};

const PageContent = () => {
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Insight />
        <section className="common-module">
          <ContentInsight />
        </section>
        <section className="common-module">
          <div className="row">
            <div className="col-xxl-8">
              <RecentUpdates />
            </div>
            <div className="col-xxl-4">
              <div className="row">
                <div className="col-xxl-12 col-lg-6">
                  <RealtimeVisitor />
                </div>
                <div className="col-xxl-12 col-lg-6">
                  <SocialMedia />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default function Dashboard({ useAuth }) {
  const [sidebar_active, setSidebarActive] = React.useState(false);
  return (
    <DashboardLayout useAuth={useAuth}>
      <PageContent />
    </DashboardLayout>
  );
}
