export const TESTIMONIAL_LIST_LOADING = "TESTIMONIAL_LIST_LOADING";
export const GET_TESTIMONIAL_LIST = "GET_TESTIMONIAL_LIST";
export const GET_TESTIMONIAL_LIST_ERROR = "GET_TESTIMONIAL_LIST_ERROR";

export const GET_TESTIMONIAL_BY_ID_LOADING = "GET_TESTIMONIAL_BY_ID_LOADING";
export const GET_TESTIMONIAL_BY_ID_SUCCESS = "GET_TESTIMONIAL_BY_ID_SUCCESS";
export const GET_TESTIMONIAL_BY_ID_ERROR = "GET_TESTIMONIAL_BY_ID_ERROR";

export const DELETEING_TESTIMONIAL = "DELETEING_TESTIMONIAL";
export const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL";
export const DELETE_TESTIMONIAL_ERROR = "DELETE_TESTIMONIAL_ERROR";

export const DELETED_TESTIMONIAL_LIST_LOADING = 'DELETED_TESTIMONIAL_LIST_LOADING';
export const GET_DELETED_TESTIMONIAL_LIST= 'GET_DELETED_TESTIMONIAL_LIST';
export const GET_DELETED_TESTIMONIAL_LIST_ERROR = 'GET_DELETED_TESTIMONIAL_LIST_ERROR';

export const PERMA_DELETEING_TESTIMONIAL = 'PERMA_DELETEING_TESTIMONIAL';
export const PERMA_DELETE_TESTIMONIAL = 'PERMA_DELETE_TESTIMONIAL';
export const PERMA_DELETE_TESTIMONIAL_ERROR = 'PERMA_DELETE_TESTIMONIAL_ERROR';

export const RESTORE_TESTIMONIAL_LIST_LOADING = 'RESTORE_TESTIMONIAL_LIST_LOADING';
export const RESTORE_TESTIMONIAL_LIST = 'RESTORE_TESTIMONIAL_LIST';
export const RESTORE_TESTIMONIAL_LIST_ERROR = 'RESTORE_TESTIMONIAL_LIST_ERROR';
