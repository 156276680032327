import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList, deleteEntityById } from "../../Redux/User/actions";

function List({ useAuth, getList, list, onDelete }) {
  const [filter_options, setFilterOptions] = useState([
    {value: "admin", text: "Admin"},
    {value: "superadmin", text: "Super Admin"},
    {value: "member", text: "Member"}
  ])
  const tableConfig = {
    title: "Users",
    filterOptionTitle: "User Type",
    filterOptions: filter_options,
    applyFilter: true,
    tableColumns: [
      {name: "Name", selector: 'name', width: '70%'},
      {name: "Updated At", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '2%'}
    ],
    filterByColumn: 'type',
    searchByColumn: 'name',
    addPath: '/user/add',
    updatePath: "/user/update",
    noDataMessage: "No user exists",
    deleteBtnText: 'Delete',
    updateLinkColumn: 'name',
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),    
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
