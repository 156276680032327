import React, { useEffect } from "react";
import { Form, Input, Select, Col, Row, Switch, Alert } from "antd";
import { useHistory } from "react-router-dom";
import DashboardActionBar from "../DashboardActionBar";

const { Option } = Select;

const UserForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading; // || data.loading

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
  }, []);

  const userTypeData = [
    { label: "Super Admin", value: "superadmin" },
    { label: "Admin", value: "admin" },
    { label: "Member", value: "member" },
  ];

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            name: values.name,
            type: values.type,
            status: values.status ? 1 : 0,
            email: values.email,
          },
          tokenData,
          params.id
        );
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  // const onFinish = (values) => {
  //   if (isAddForm) {
  //     createEntity(
  //       {
  //         ...values,
  //         status: values.status ? 1 : 0,
  //       },
  //       tokenData
  //     ).then(() =>{
  //       message.success('Category is added!')
  //       loadCategory()
  //     });
  //   } else if (isUpdateForm) {
  //     updateEntityInfo(
  //       {
  //         ...values,
  //         status: values.status ? 1 : 0,
  //         is_featured: values.is_featured ? 1 : 0,
  //         description,
  //         featured_image: response.data,
  //       },
  //       tokenData,
  //       params.id
  //     ).then(() => {
  //       message.success('Category is updated!')
  //       loadEntityById()
  //       loadCategory()
  //     });
  //   }
  // };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      name: data.dataById?.data.name,
      user_name: data.dataById?.data.user_name,
      status: data.dataById?.data.status == 1 ? true : false,
      email: data.dataById?.data.email,
      type: data.dataById?.data.type,
      password: data.dataById?.data.password,
      password_confirmation: data.dataById?.data.password_confirmation,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add User",
    titleToUpdate: "Update User",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update User",
    taskCompleteAddMessage: "User is added!",
    taskCompleteUpdateMessage: "User is updated!",
    discardLink: "users",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <div className="common-module bg-white mt-3">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                  { min: 5, max: 255, message: "Invalid Name" },
                ]}
              >
                <Input placeholder="Full Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="User type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "User Type is required",
                  },
                ]}
              >
                <Select>
                  {userTypeData?.map((itm, idx) => {
                    return (
                      <Option key={idx} value={itm.value}>
                        {itm.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            {!isUpdateForm && (
              <>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        min: 8,
                        message:
                          "Password should be atleast 8 characters long.",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input type="password" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    name="password_confirmation"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Password didn't match.")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input type="password" />
                  </Form.Item>
                </Col>
              </>
            )}

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <div className="switch-group">
                <Form.Item label="Status" valuePropName="checked" name="status">
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Not Active"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default UserForm;
