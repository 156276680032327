import React from "react";
import DashboardLayout from "./DashboardLayout";

export default function DashboardUpdate({useAuth, children}) {
  return (
    <DashboardLayout useAuth={useAuth}>
      <div className="page-content">
        <div className="container-fluid">
          <div className="common-module">
            {children}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
