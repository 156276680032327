import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList as getMenuList, deleteEntityById } from "../../Redux/Menu/actions";
import { getList as getMenuTypeList } from "../../Redux/MenuType/actions";

function List({ useAuth, getList, list, onDelete, loginData, getMenuTypeList, menuTypelist }) {
  let tokenData = loginData.data;
  const [filter_options, setFilterOptions] = useState();

  useEffect(() => {
    if (!list.data) {
      getList(tokenData);
    }
    if (!menuTypelist.data) {
      getMenuTypeList(tokenData);
    }
    if(menuTypelist.data){
      setFilterOptions(menuTypelist.data.data.map(e=>{return{value:e.id, text:e.type_title}}))
    }
  }, [list.data, menuTypelist.data]);



  const tableConfig = {
    title: "Menu",
    filterOptionTitle: "Menu Type",
    filterOptions: filter_options,
    applyFilter: true,
    tableColumns: [
      {name: "Order", selector: 'order', width: '3%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Title", selector: 'item_title', width: '74%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'type_id',
    searchByColumn: 'item_title',
    addPath: "/menu/add",
    updatePath: "/menu/update",
    //addTypePath: "/menu-type/add",
    addTypeText: "Add Menu Type",
    noDataMessage: "No menu exists",
    deleteBtnText: 'Delete',
    updateLinkColumn: 'item_title',
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  }

  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.menu,
    menuTypelist: state.menuType,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getMenuList(tokenData)),
    getMenuTypeList: (tokenData) => dispatch(getMenuTypeList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
