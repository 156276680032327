export const TEAM_LIST_LOADING = "TEAM_LIST_LOADING";
export const GET_TEAM_LIST = "GET_TEAM_LIST";
export const GET_TEAM_LIST_ERROR = "GET_TEAM_LIST_ERROR";

export const GET_TEAM_BY_ID_LOADING = "GET_TEAM_BY_ID_LOADING";
export const GET_TEAM_BY_ID_SUCCESS = "GET_TEAM_BY_ID_SUCCESS";
export const GET_TEAM_BY_ID_ERROR = "GET_TEAM_BY_ID_ERROR";

export const DELETEING_TEAM = "DELETEING_TEAM";
export const DELETE_TEAM = "DELETE_TEAM";
export const DELETE_TEAM_ERROR = "DELETE_TEAM_ERROR";

export const DELETED_TEAM_LIST_LOADING = 'DELETED_TEAM_LIST_LOADING';
export const GET_DELETED_TEAM_LIST= 'GET_DELETED_TEAM_LIST';
export const GET_DELETED_TEAM_LIST_ERROR = 'GET_DELETED_TEAM_LIST_ERROR';

export const PERMA_DELETEING_TEAM = 'PERMA_DELETEING_TEAM';
export const PERMA_DELETE_TEAM = 'PERMA_DELETE_TEAM';
export const PERMA_DELETE_TEAM_ERROR = 'PERMA_DELETE_TEAM_ERROR';

export const RESTORE_TEAM_LIST_LOADING = 'RESTORE_TEAM_LIST_LOADING';
export const RESTORE_TEAM_LIST = 'RESTORE_TEAM_LIST';
export const RESTORE_TEAM_LIST_ERROR = 'RESTORE_TEAM_LIST_ERROR';
