import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Col,
  Row,
  Tabs,
  Switch,
  DatePicker,
  InputNumber,
  TreeSelect,
  Alert,
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getList as getCategoryList } from "../../Redux/Category/actions";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";
import ImageField from "../Core/ImageField";

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import DashboardActionBar from "../DashboardActionBar";

const { Option } = Select;
const { TabPane } = Tabs;
const { TreeNode } = TreeSelect;

const PackageForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getCategoryList,
  categoryListData,
}) => {
  let history = useHistory();
  const [errors, showErrors] = useState({});
  const tokenData = loginData.data;
  const [has_update_form_values, setUpdateFormValues] = useState(false);
  const [package_details, setPackageDetails] = useState();
  const [package_cost_includes, setPackageCostIncludes] = useState();
  const [package_cost_excludes, setPackageCostExcludes] = useState();
  const [package_highlights, setPackageHighlights] = useState();
  const [package_departure_note, setPackageDepatureNote] = useState();
  const [package_info, setPackageInfo] = useState();
  const [featured_image, setFeaturedImage] = useState(
    isUpdateForm &&
      data.dataById &&
      data.dataById.data.featured_image &&
      data.dataById.data.featured_image.full_path
  );
  console.log(data);
  const [image_crop_open, setImageCropOpen] = useState(false);
  const [categoriesIDList, setCategoriesIDList] = useState();

  const [form] = Form.useForm();
  const { validateFields, setFieldsValue, getFieldValue } = form;
  const loading = selectedForm.loading || categoryListData.loading; // || data.loading

  const loadEntityById = async () => {
    getEntityById(tokenData, params.id);
  };

  const loadCategory = async () => {
    await getCategoryList(tokenData);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }

    if (!categoryListData.data) {
      loadCategory();
    }

    if (categoryListData.data) {
      setCategoriesIDList(
        categoryListData.data.data.filter(
          (e) => e.type.category_key === "destination"
        )
      );
    }
  }, [categoryListData.data]);

  const packageDestination = categoryListData.data?.data
    .filter((e) => e.type.category_key === "destination")
    .map((a) => {
      return { value: a.id, label: a.title };
    });
  const packageGrade = categoryListData.data?.data
    .filter((e) => e.type.category_key === "trip_gradings")
    .map((a) => {
      return { value: a.id, label: a.title };
    });
  const packageStyles = categoryListData.data?.data
    .filter((e) => e.type.category_key === "trip_style")
    .map((a) => {
      return { value: a.id, label: a.title };
    });
  const packageTransportation = categoryListData.data?.data
    .filter((e) => e.type.category_key === "transportation")
    .map((a) => {
      return { value: a.id, label: a.title };
    });
  const packageAccommodation = categoryListData.data?.data
    .filter((e) => e.type.category_key === "accommodation")
    .map((a) => {
      return { value: a.id, label: a.title };
    });
  const packageMeals = categoryListData.data?.data
    .filter((e) => e.type.category_key === "meals")
    .map((a) => {
      return { value: a.id, label: a.title };
    });

  
    let categoriesOptions = categoriesIDList?.map((itm) => {
      const { id, title, children } = itm;
      return {
        value: id,
        label: title,
        ...(children && {
          children: children?.map((child) => {
            const { id, title, children } = child;
            return {
              value: id,
              label: title,
              ...(children && {
                children: children?.map((gChild) => {
                  const { id, title } = gChild;
                  return {
                    value: id,
                    label: title,
                  };
                }),
              }),
            };
          }),
        }),
      };
    });
  

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const axios = require("axios");
      if (action === "add") {
        console.log(values);
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  package_details,
                  package_highlights,
                  package_cost_includes,
                  package_cost_excludes,
                  package_departure_note,
                  package_info,
                  featured_image: response.data,
                },
                tokenData
              ).then(() => {
                loadCategory();
              });
            });
        } else {
          createEntity(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              package_details,
              package_highlights,
              package_cost_includes,
              package_cost_excludes,
              package_departure_note,
              package_info,
              featured_image,
            },
            tokenData
          ).then(() => {
            loadCategory();
          });
        }
      } else if (action === "addandexit") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  package_details,
                  package_highlights,
                  package_cost_includes,
                  package_cost_excludes,
                  package_departure_note,
                  package_info,
                  featured_image: response.data,
                },
                tokenData,
                true,
                history
              ).then(() => {
                loadCategory();
              });
            });
        } else {
          createEntity(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              package_details,
              package_highlights,
              package_cost_includes,
              package_cost_excludes,
              package_departure_note,
              package_info,
              featured_image,
            },
            tokenData,
            true,
            history
          ).then(() => {
            loadCategory();
          });
        }
      } else if (action === "update") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              updateEntityInfo(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  package_details,
                  package_highlights,
                  package_cost_includes,
                  package_cost_excludes,
                  package_departure_note,
                  package_info,
                  featured_image: response.data,
                },
                tokenData,
                params.id
              ).then(() => {
                loadEntityById();
                loadCategory();
              });
            });
        } else {
          updateEntityInfo(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              package_details,
              package_highlights,
              package_cost_includes,
              package_cost_excludes,
              package_departure_note,
              package_info,
              featured_image,
            },
            tokenData,
            params.id
          ).then(() => {
            loadEntityById();
            loadCategory();
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setPackageDetails("");
    setFeaturedImage("");
    setPackageHighlights("");
    setPackageCostIncludes("");
    setPackageCostExcludes("");
    setPackageDepatureNote("");
    setPackageInfo("");
  };

  if (isUpdateForm) {
    setFieldsValue({
      package_title: data.dataById?.data.package_title,
      url_title: data.dataById?.data.urlinfo?.url_title,
      slug: data.dataById?.data.urlinfo?.url_slug,
      package_details: data.dataById?.data.package_details,
      package_highlights: data.dataById?.data.package_highlights,
      package_cost_includes: data.dataById?.data.package_cost_includes,
      package_cost_excludes: data.dataById?.data.package_cost_excludes,
      package_price: data.dataById?.data.package_price,
      package_duration: data.dataById?.data.package_duration,
      package_duration_type: data.dataById?.data.package_duration_type,
      status: data.dataById?.data.status == 1 ? true : false,
      is_featured: data.dataById?.data.is_featured == 1 ? true : false,
      package_destination: data.dataById?.data.package_destination,
      meta_title: data.dataById?.data.meta?.meta_title,
      meta_keywords: data.dataById?.data.meta?.meta_keywords,
      meta_description: data.dataById?.data.meta?.meta_description,
      featured_image: data.dataById?.data.featured?.thumb_path,
      featured_alt: data.dataById?.data.featured?.alt_text,
      featured_caption: data.dataById?.data.featured?.caption,
      package_grade: data.dataById?.data.package_grade,
      package_style: data.dataById?.data.package_style,
      package_transportation: data.dataById?.data.package_transportation,
      package_meals: data.dataById?.data.package_meals,
      package_accommodation: data.dataById?.data.package_accommodation,
      package_group_size: data.dataById?.data.package_group_size,
      facts: data.dataById?.data.facts,
      package_departure_note: data.dataById?.data.package_departure_note,
      departures: data.dataById?.data.departures,
      categories: data.dataById?.data.categories.map((a) => {
        return { label: a.title, value: a.id };
      }),
      banners: data.dataById?.data.banners,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Package",
    titleToUpdate: "Update Package",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Package",
    discardLink: "packages",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        initialValues={{ meta_title: form.setFieldsValue("package_title") }}
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <div className="common-module bg-white mt-3">
          <Tabs defaultActiveKey="1" className="custom-tab">
            <TabPane tab={<span>Overview</span>} key="1">
              <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label="Package Title"
                    name="package_title"
                    rules={[
                      {
                        required: true,
                        message: "Package title is required",
                      },
                      { min: 5, max: 255, message: "Invalid package title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label="Url Title"
                    name="url_title"
                    rules={[
                      {
                        required: true,
                        message: "Url title is required",
                      },
                      { min: 5, max: 255, message: "Invalid package title" },
                    ]}
                  >
                    <Input
                      onChange={(e) =>
                        form.setFieldsValue({ slug: slugify(e.target.value) })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label={
                      <span className="ant-form-item-optional">
                        Auto Generate from Url Title
                      </span>
                    }
                    name="slug"
                    title="Auto Generate from Url Title"
                    rules={[
                      {
                        required: true,
                        message: "Slug is required",
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label="Categories"
                    name="categories"
                    rules={[
                      {
                        required: true,
                        message: "Category is required",
                      },
                    ]}
                  >
                    <TreeSelect
                      showSearch
                      style={{ width: "100%" }}
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      placeholder="Please select"
                      allowClear
                      multiple
                      treeDefaultExpandAll
                    >
                      {categoriesOptions?.map((parent) => {
                        const { value, label, children } = parent;
                        return (
                          <TreeNode key={value} value={value} title={label}>
                            {children.length !== 0 &&
                              children?.map((child) => {
                                const { value, label, children } = child;
                                return (
                                  <TreeNode
                                    key={value}
                                    value={value}
                                    title={label}
                                  >
                                    {children.length !== 0 &&
                                      children?.map((gChild) => {
                                        const { value, label } = gChild;
                                        return (
                                          <TreeNode
                                            key={value}
                                            value={value}
                                            title={label}
                                          />
                                        );
                                      })}
                                  </TreeNode>
                                );
                              })}
                          </TreeNode>
                        );
                      })}
                    </TreeSelect>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={16}>
                  <div className="switch-group">
                    <Form.Item
                      label="Status"
                      valuePropName="checked"
                      name="status"
                    >
                      <Switch
                        checkedChildren="Publish"
                        unCheckedChildren="Unpublish"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Is Featured?"
                      valuePropName="checked"
                      name="is_featured"
                    >
                      <Switch
                        checkedChildren="Featured"
                        unCheckedChildren="Not Featured"
                      />
                    </Form.Item>
                  </div>
                </Col>

                <Col className="gutter-row" xs={24} xl={16}>
                  <Form.Item label="Description" name="package_details">
                    <TextEditor
                      value={package_details}
                      initialValue={isUpdateForm && package_details}
                      onEditorChange={(e) => {
                        setPackageDetails(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={8}>
                  <label className="form-label">Meta</label>
                  <div className="card bg-white mb-4 mb-xl-0">
                    <div className="card-body">
                      <Form.Item
                        label="Meta Title"
                        name="meta_title"
                        rules={[
                          {
                            required: true,
                            message: "Meta title is required",
                          },
                          { min: 5, max: 255, message: "Invalid meta title" },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Meta Keywords"
                        name="meta_keywords"
                        rules={[
                          {
                            min: 5,
                            max: 255,
                            message: "Invalid meta keywords",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        className="mb-0"
                        label="Meta Description"
                        name="meta_description"
                      >
                        <TextArea rows={8} />
                      </Form.Item>
                    </div>
                  </div>
                </Col>

                <Col className="gutter-row" xs={24} xl={10}>
                  <div className="card bg-white mb-4 mb-xl-0">
                    <div className="card-body">
                      <Form.Item>
                        <ImageField
                          title="Featured Image"
                          image={
                            featured_image ||
                            (isUpdateForm &&
                              data.dataById &&
                              data.dataById.data.featured_image &&
                              data.dataById.data.featured_image.full_path)
                          }
                          height="300"
                          width="400"
                          setImage={(image_data) => {
                            setFeaturedImage(image_data);
                            setImageCropOpen(true);
                          }}
                        />
                        {featured_image && image_crop_open && (
                          <ImageCrop
                            image={featured_image}
                            setCroppedImage={setFeaturedImage}
                            setImageCropOpen={setImageCropOpen}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        style={{ marginTop: "-20px" }}
                        label="Alt Text"
                        name="featured_alt"
                        rules={[
                          { min: 5, max: 50, message: "Invalid Alt Text" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Caption"
                        name="featured_caption"
                        rules={[
                          { min: 5, max: 50, message: "Invalid caption" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col className="gutter-row banner-image" xs={24} xl={14}>
                  <Form.List name="banners" className="bg-white">
                    {(fields, { add, remove }) => (
                      <>
                        <h6>Banner Images</h6>
                        {fields.map((field, index) => (
                          <div
                            key={field.key}
                            className="card bg-white"
                            align="baseline"
                          >
                            <div class="card-body">
                              <label className="form-label">
                                Banner #{index + 1}{" "}
                                <span
                                  class="btn-delate"
                                  onClick={() => remove(field.name)}
                                >
                                  Delete
                                </span>
                              </label>

                              <Row>
                                <Col xs={24} lg={6}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "banner_image"]}
                                  >
                                    <div class="input-group">
                                      <input type="file" />
                                    </div>
                                  </Form.Item>
                                </Col>

                                <Col xs={24} lg={6}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "banner_alt"]}
                                  >
                                    <Input placeholder="Alt Text" />
                                  </Form.Item>
                                </Col>

                                <Col xs={24} lg={12}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "banner_caption"]}
                                  >
                                    <Input placeholder="banner caption" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add New Banner
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </TabPane>

            <TabPane tab={<span>Cost & Highlighs </span>} key="2">
              <Row>
                <Col className="gutter-row" xs={24}>
                  <Form.Item label="Cost Includes" name="package_cost_includes">
                    <TextEditor
                      simpleTollbar
                      smallSize
                      value={package_cost_includes}
                      initialValue={isUpdateForm && package_cost_includes}
                      onEditorChange={(e) => {
                        setPackageCostIncludes(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Cost Exclude" name="package_cost_excludes">
                    <TextEditor
                      simpleTollbar
                      smallSize
                      value={package_cost_excludes}
                      initialValue={isUpdateForm && package_cost_excludes}
                      onEditorChange={(e) => {
                        setPackageCostExcludes(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item label="Highlights" name="package_highlights">
                    <TextEditor
                      simpleTollbar
                      smallSize
                      value={package_highlights}
                      initialValue={isUpdateForm && package_highlights}
                      onEditorChange={(e) => {
                        setPackageHighlights(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={<span>Facts & Depature</span>} key="3">
              <div className="trip_facts">
                <h4>Trip Facts</h4>
                <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item
                      label="Package Price"
                      name="package_price"
                      rules={[
                        {
                          required: true,
                          message: "Package Price is required",
                        },
                      ]}
                    >
                      <InputNumber
                        addonAfter={
                          <Select defaultValue="USD">
                            <Option value="USD">$</Option>
                            <Option value="EUR">€</Option>
                            <Option value="GBP">£</Option>
                            <Option value="AUD">A$</Option>
                          </Select>
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item
                      label="Package Durations"
                      name="package_duration"
                      rules={[
                        {
                          required: true,
                          message: "Package Duration is required",
                        },
                      ]}
                    >
                      <InputNumber
                        addonAfter={
                          <Form.Item noStyle name="package_duration_type">
                            <Select style={{ width: 100 }}>
                              <Option value="days">Days</Option>
                              <Option value="hrs">Hours</Option>
                            </Select>
                          </Form.Item>
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item
                      label="Package Destination"
                      name="package_destination"
                      rules={[
                        {
                          required: true,
                          message: "Package Destination is required",
                        },
                      ]}
                    >
                      <Select>
                        {packageDestination?.map((itm, idx) => {
                          return (
                            <Option key={idx} value={itm.value}>
                              {itm.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item label="Trip Grade" name="package_grade">
                      <Select>
                        {packageGrade?.map((itm, idx) => {
                          return (
                            <Option key={idx} value={itm.value}>
                              {itm.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item label="Trip Style" name="package_style">
                      <Select>
                        {packageStyles?.map((itm, idx) => {
                          return (
                            <Option key={idx} value={itm.value}>
                              {itm.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item
                      label="Transportation"
                      name="package_transportation"
                    >
                      <Select>
                        {packageTransportation?.map((itm, idx) => {
                          return (
                            <Option key={idx} value={itm.value}>
                              {itm.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item
                      label="Accommodation"
                      name="package_accommodation"
                    >
                      <Select>
                        {packageAccommodation?.map((itm, idx) => {
                          return (
                            <Option key={idx} value={itm.value}>
                              {itm.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item label="Meals" name="package_meals">
                      <Select>
                        {packageMeals?.map((itm, idx) => {
                          return (
                            <Option key={idx} value={itm.value}>
                              {itm.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item label="Group Size" name="package_group_size">
                      <InputNumber min="1" max="100" />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item label="Max Altitude" name="package_max_altitude">
                      <InputNumber min={1} max={8848} />
                    </Form.Item>
                  </Col>

                  <Form.List name="facts">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Col className="gutter-row" xs={24} sm={12} xl={8}>
                            <Row
                              gutter={{ xs: 5, sm: 5, md: 5 }}
                              key={field.key}
                              align="baseline"
                            >
                              <span
                                className="btn-delate"
                                onClick={() => remove(field.name)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                  />
                                </svg>
                              </span>
                              <Col xs={8}>
                                <Form.Item
                                  {...field}
                                  label={`Fact #${index + 1}`}
                                  name={[field.name, "fact_title"]}
                                >
                                  <Input placeholder="Title" />
                                </Form.Item>
                              </Col>
                              <Col xs={16}>
                                <Form.Item
                                  {...field}
                                  label="&nbsp;"
                                  name={[field.name, "fact_value"]}
                                >
                                  <Input placeholder="Value" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                        <Col>
                          <Form.Item style={{ paddingTop: "40px" }}>
                            <button
                              type="button"
                              onClick={() => add()}
                              className="btn btn-outline-secondary rounded-pill font-weight-semibold"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-plus"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                              </svg>
                              Add New Facts
                            </button>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Form.List>
                </Row>
              </div>
              <div className="departures">
                <h4>Depature Dates</h4>

                <Form.Item label="Depature note" name="package_departure_note">
                  <TextEditor
                    simpleTollbar
                    smallSize
                    value={package_departure_note}
                    initialValue={isUpdateForm && package_departure_note}
                    onEditorChange={(e) => {
                      setPackageDepatureNote(e.target.value);
                    }}
                  />
                </Form.Item>

                <Form.List name="departures">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <>
                          <div className="ant-form-item-label">
                            <label>Depature Date #{index + 1}</label>{" "}
                            <span
                              className="btn-delate"
                              onClick={() => remove(field.name)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>{" "}
                              Delete
                            </span>
                          </div>

                          <div key={field.key} className="depature-list">
                            <Form.Item
                              {...field}
                              name={[field.name, "departure_date"]}
                            >
                              <DatePicker format="YYYY-MM-DD" />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "departure_cost"]}
                            >
                              <Input placeholder="Depature cost" />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "departure_note"]}
                            >
                              <Input placeholder="Depature note" />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              name={[field.name, "departure_status"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Depature Status is required",
                                },
                              ]}
                            >
                              <Select placeholder="Depature Status">
                                <Option value="Guaranteed">Guaranteed</Option>
                                <Option value="Available">Available</Option>
                                <Option value="Limited">Limited</Option>
                                <Option value="Closed">Closed</Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </>
                      ))}
                      <Form.Item>
                        <button
                          type="button"
                          onClick={() => add()}
                          className="btn btn-outline-secondary rounded-pill font-weight-semibold"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-plus"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                          </svg>
                          Add new depature date
                        </button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            </TabPane>
            <TabPane tab={<span>Others</span>} key="4">
              <Form.Item label="Useful Information" name="package_info">
                <TextEditor
                  value={package_info}
                  initialValue={isUpdateForm && package_info}
                  onEditorChange={(e) => {
                    setPackageInfo(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Faqs" name="package_faqs">
                <Select
                  mode="multiple"
                  placeholder="Faqs"
                  optionLabelProp="label"
                >
                  {categoriesOptions &&
                    categoriesOptions.map((itm) => {
                      return (
                        <Option value={itm.value} label={itm.label}>
                          {itm.label}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item label="Useful Info" name="package_useful_info">
                <Select
                  mode="multiple"
                  placeholder="Useful Info"
                  optionLabelProp="label"
                >
                  {categoriesOptions &&
                    categoriesOptions.map((itm) => {
                      return (
                        <Option value={itm.value} label={itm.label}>
                          {itm.label}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryListData: state.category,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryList: (tokenData) => dispatch(getCategoryList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageForm);
