export const MENU_LIST_LOADING = "MENU_LIST_LOADING";
export const GET_MENU_LIST = "GET_MENU_LIST";
export const GET_MENU_LIST_ERROR = "GET_MENU_LIST_ERROR";

export const GET_MENU_BY_ID_LOADING = "GET_MENU_BY_ID_LOADING";
export const GET_MENU_BY_ID_SUCCESS = "GET_MENU_BY_ID_SUCCESS";
export const GET_MENU_BY_ID_ERROR = "GET_MENU_BY_ID_ERROR";

export const DELETEING_MENU = "DELETEING_MENU";
export const DELETE_MENU = "DELETE_MENU";
export const DELETE_MENU_ERROR = "DELETE_MENU_ERROR";

export const DELETED_MENU_LIST_LOADING = 'DELETED_MENU_LIST_LOADING';
export const GET_DELETED_MENU_LIST= 'GET_DELETED_MENU_LIST';
export const GET_DELETED_MENU_LIST_ERROR = 'GET_DELETED_MENU_LIST_ERROR';

export const PERMA_DELETEING_MENU = 'PERMA_DELETEING_MENU';
export const PERMA_DELETE_MENU = 'PERMA_DELETE_MENU';
export const PERMA_DELETE_MENU_ERROR = 'PERMA_DELETE_MENU_ERROR';

export const RESTORE_MENU_LIST_LOADING = 'RESTORE_MENU_LIST_LOADING';
export const RESTORE_MENU_LIST = 'RESTORE_MENU_LIST';
export const RESTORE_MENU_LIST_ERROR = 'RESTORE_MENU_LIST_ERROR';
