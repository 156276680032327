export const ITINERARY_LIST_LOADING = "ITINERARY_LIST_LOADING";
export const GET_ITINERARY_LIST = "GET_ITINERARY_LIST";
export const GET_ITINERARY_LIST_ERROR = "GET_ITINERARY_LIST_ERROR";

export const GET_ITINERARY_BY_ID_LOADING = "GET_ITINERARY_BY_ID_LOADING";
export const GET_ITINERARY_BY_ID_SUCCESS = "GET_ITINERARY_BY_ID_SUCCESS";
export const GET_ITINERARY_BY_ID_ERROR = "GET_ITINERARY_BY_ID_ERROR";

export const DELETEING_ITINERARY = "DELETEING_ITINERARY";
export const DELETE_ITINERARY = "DELETE_ITINERARY";
export const DELETE_ITINERARY_ERROR = "DELETE_ITINERARY_ERROR" 

export const DELETED_ITINERARY_LIST_LOADING = "DELETED_ITINERARY_LIST_LOADING";
export const GET_DELETED_ITINERARY_LIST = "GET_DELETED_ITINERARY_LIST";
export const GET_DELETED_ITINERARY_LIST_ERROR = "GET_DELETED_ITINERARY_LIST_ERROR";

export const RESTORE_ITINERARY_LIST_LOADING = "RESTORE_ITINERARY_LIST_LOADING";
export const RESTORE_ITINERARY_LIST = "RESTORE_ITINERARY_LIST";
export const RESTORE_ITINERARY_LIST_ERROR = "RESTORE_ITINERARY_LIST_ERROR";

export const PERMA_DELETEING_ITINERARY = "PERMA_DELETEING_ITINERARY";
export const PERMA_DELETE_ITINERARY = "PERMA_DELETE_ITINERARY";
export const PERMA_DELETE_ITINERARY_ERROR = "PERMA_DELETE_ITINERARY_ERROR" 
