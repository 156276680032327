import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import DashboardList from "../../Components/Layouts/DashboardList";
import {getDeletedList, permanentDeleteEntity, restoretDeleteEntity} from "../../Redux/Author/actions";



function DeletedList({ useAuth, getList, list, onDelete, onRestore}) {
  const location = useLocation();
  const tableConfig = {
    title: "Deleted Author(s)",
    filterOptionTitle: "Author Type",
    filterOptions: [
      "admin",
      "superadmin",
      "member",
    ],
    defaultFilterValue: 'admin',
    applyFilter: false,
    tableColumns: [
      {name: "Status", selector: 'status', width: '5%'},
      {name: "Name", selector: 'name', width: '75%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'name',
    searchByColumn: 'name',
    updatePath: "/blog/author/update",    
    addPath: "/blog/author/add",
    restorePath: "/blog/authors",
    noDataMessage: "No Author exists",
    updateLinkColumn: 'name',
    deleteBtnText: 'Permanent Delete',
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList)
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}



const mapStateToProps = (state) => {
  return {
    list: state.author,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getDeletedList(tokenData)),
    onDelete: (idList) => dispatch(permanentDeleteEntity(idList)),
    onRestore: (idList) => dispatch(restoretDeleteEntity(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletedList);
