import React from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList, deleteEntityById } from "../../Redux/Faqs/actions";

function List({ useAuth, getList, list, onDelete}) {
  const tableConfig = {
    title: "Faqs",
    tableColumns: [
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Order", selector: 'order', width: '3%'},
      {name: "Title", selector: 'question', width: '70%'},
      {name: "Modified Date", selector: 'updated_at', width: '17%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'question',
    searchByColumn: 'question',
    updatePath: "/faq/update", 
    addPath: "/faq/add",
    noDataMessage: "No Faqs exists",
    updateLinkColumn: 'question',
    deleteBtnText: 'Delete',
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
  }

  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.faqs,  
    loginData: state.loginData, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
