import { Table, notification, Modal, Spin, Anchor } from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { Link, useHistory } from "react-router-dom";
import { arrayMoveImmutable } from "array-move";
import Moment from "react-moment";

const Status = ({ id, status_checked }) => (
  <div className="status">
    <span className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={status_checked == 0 ? false : true}
        id={id}
      />
      <label className="form-check-label" for={id}></label>
    </span>
  </div>
);

const FilterComponent = ({ searchText, onSearch, searchByColumn }) => (
  <div className="input-search">
    <input
      id="search"
      className="form-control"
      type="text"
      placeholder={`Search By key...`}
      aria-label="Search Input"
      value={searchText}
      onChange={onSearch}
    />
  </div>
);

const Sorting = ({
  searchText,
  setSearchText,
  setFilter,
  filterText,
  filterOptionTitle,
  filterOptions,
  searchByColumn,
  applyFilter,
}) => {
  const handleClear = () => {
    if (searchText) {
      setSearchText("");
    }
  };

  return (
    <>
      {applyFilter && (
        <div className="category-sorting">
          <label for="catlist">{filterOptionTitle}:</label>
          <div className="custom_select">
            <select
              id="catlist"
              className="form-control"
              onChange={(e) => setFilter(e.target.value)}
              value={filterText}
            >
              <option value="all"> All </option>
              {filterOptions &&
                filterOptions.map((category, idx) => (
                  <option value={category.value} key={idx}>
                    {category.text}
                  </option>
                ))}
            </select>
            <i class="select_indicator"></i>
          </div>
        </div>
      )}

      <FilterComponent
        onSearch={(e) => setSearchText(e.target.value)}
        onClear={handleClear}
        searchText={searchText}
        searchByColumn={searchByColumn}
      />
    </>
  );
};


const DragHandle = sortableHandle(() => (
  <i className="bi-arrows-expand" style={{ cursor: "grab", color: "#999" }}></i>
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const CustomDataTable = ({
  title,
  filterOptionTitle,
  updateLinkColumn,
  filterOptions,
  tableData,
  tableColumns,
  searchByColumn,
  filterByColumn,
  applyFilter,
  updatePath,
  addPath,
  addTypePath,
  addTypeText,
  restorePath,
  dataToList,
  deleteBtnText,
  deleteItemsByIdList,
  restoreItemsByIdList,
}) => {
  const [dataSource, setDataSource] = useState(() => tableData);
  const [selectedItems, setSelectedItems] = useState();
  const [searchText, setSearchText] = useState("");
  const [filterText, setFilter] = useState();

  const openNotificationWithIcon = (type, message) => {
    notification.open({
      type: type,
      message: message,
       });
  };


  const history = useHistory();

  useEffect(() => {
    if (tableData) {
      setDataSource(() => tableData);
    }

    if(filterText){
      if(filterText === "all"){
        setDataSource(tableData)
      }else{
       setDataSource(
        tableData.filter(
          item => 
          item[filterByColumn]&&item[filterByColumn] === filterText
        )
       )
      }
    }

    if (searchText) {
      setDataSource(
        dataSource.filter(
          (item) =>
            item[searchByColumn]&&item[searchByColumn]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
        )
      );
    }
  }, [tableData, searchText, filterText]);

  const columns = tableColumns.map((x) => {
    return {
      title: x.name,
      dataIndex: x.selector,
      width: x.width,
      ...(x.name === "Order" && {
        render: () => <DragHandle />,
        className: "drag-visible",
      }),
      ...(x.name === "Status" && { className: "drag-visible" }),
    };
  });

  if(title !== "Categories" && title !== "Deleted Categorie(s)"){
  columns.splice(0, 0, {
    title: "S.N",
    dataIndex: "sn",
    width: "3%",
  });
}


  let data = dataSource.map((x, idx) => {
    return {
      ...x,
      [updateLinkColumn]: (
        <Link className={`link`} to={`${updatePath}/${x.id}`}>
          {x[updateLinkColumn]}
        </Link>
      ),
      sn: idx + 1,
      index: (idx+1),
      key: ""+(idx+1),
      status: <Status id={x.id} status_checked={x.status} />,
      updated_at: <Moment fromNow ago>{x.updated_at}</Moment>,
      ...(x.children && {
        children: x.children?.map((i, jdx) => {
          return{
            ...i,
            [updateLinkColumn]: (
              <Link className={`link`} to={`${updatePath}/${i.id}`}>
                {i[updateLinkColumn]}
              </Link>
            ), 
          index: Math.floor("" + (idx+1) + (jdx+1)),     
          key: "" + (idx+1) + (jdx+1),     
          status: <Status id={i.id} status_checked={i.status} />,
          updated_at: <Moment fromNow ago>{i.updated_at}</Moment>,
          ...(i.children && {
            children: i.children?.map((k, kdx) => {
              return{
                ...k,
                [updateLinkColumn]: (
                  <Link className={`link`} to={`${updatePath}/${k.id}`}>
                    {k[updateLinkColumn]}
                  </Link>
                ),              
              index: Math.floor("" + (idx+1) + (jdx+1) + (kdx+1)),         
              key: "" + (idx+1) + (jdx+1) + (kdx+1),         
              status: <Status id={k.id} status_checked={k.status} />,
              updated_at: <Moment fromNow ago>{k.updated_at}</Moment>,
              }
            })
          })
          }
        })
      })
    };
  });


  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(data),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  let DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const handleDelete = () => {
    Modal.confirm({
      title: "Delete",
      content: "Are you sure, you want to delete?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: async () => {
        deleteItemsByIdList(selectedItems.map((x) => x.id));
        openNotificationWithIcon('success', `${title} deleted successfully`);
        setSelectedItems()
      }, 
    })
  };

  const handleRestore = () => {
    Modal.confirm({
      title: "Restore",
      content: "Are you sure, you want to restore?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {
        restoreItemsByIdList(selectedItems.map((x) => x.id));
        openNotificationWithIcon('success', `${title} restore successfully`);
        setSelectedItems()
        history.push(restorePath);
      }, 
    })
  };

  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedItems(selectedRows);
    },
    onSelectNone: () => {
      setSelectedItems();
    }
  };

  return (
    <>
      <div className="common-module">
        <div className="action-bar">
          <div className="title">
            <h1>{title}</h1>
          </div>
          <div className="action-slot">
            <ul>
              <li>
                <Link to={addPath} className="btn btn-primary" id="add_new">
                  <i className="bi-plus-circle"></i> Add New
                </Link>
              </li>
              {localStorage.admin_type == "super" && addTypePath && (
                <li>
                  <Link
                    to={addTypePath}
                    className="btn btn-warning"
                    id="add_new"
                  >
                    <i className="bi-plus"></i> {addTypeText}
                  </Link>
                </li>
              )}
              {selectedItems && selectedItems.length ? (
                <li>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDelete}
                  >
                    <i className="bi-x"></i> {deleteBtnText}
                  </button>
                </li>
              ) : (
                ""
              )}
              {restoreItemsByIdList && selectedItems && selectedItems.length ? (
                <li>
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={handleRestore}
                  >
                    <i className="bi-clock"></i> Restore
                  </button>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="common-module data-table bg-white">
        <div className="sorting-wrapper py-2">
          <div className="alert-text">
            <i className="bi-info-circle text-primary"></i> {data.length}{" "}
            {title} are listed
          </div>
          <Sorting
            {...{
              filterOptionTitle,
              filterOptions,
              searchText,
              setFilter,
              filterText,
              setSearchText,
              searchByColumn,
              filterByColumn,
              applyFilter,
            }}
          />
        </div>

        <Table
          columns={columns}
          pagination={{ size: 10 }}
          rowSelection={{ ...rowSelection }}
          rowKey="index"
          expandableRowIcon={"+"}
          loading={dataToList.loading && <Spin />}
          dataSource={data}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />

        {/* <DataTable
          columns={columns}
          noHeader
          subHeader
          subHeaderComponent={
            <Category
              {...{
                filterOptionTitle,
                filterOptions,
                searchText,
                setFilter,
                filterText,
                setSearchText,
                searchByColumn,
                filterByColumn,
                applyFilter,
              }}
            />
          }
          subHeaderAlign="left"
          pagination
          data={applyFilter ? (filterText === undefined ? data : filteredItems) : filteredItems}
          selectableRows
          persistTableHead
          noDataComponent={
            dataToList.loading ? (
              <div className="spinner-border m-5" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              React__default["default"].createElement(
                "div",
                { style: { padding: "24px" } },
                noDataMessage
              )
            )
          }
          selectableRowsComponentProps={{ inkDisabled: true }}          
          customStyles={customStyles}  
          onSelectedRowsChange={(obj) => {
            setSelectedItems(obj.selectedRows);
          }}
        /> */}
      </div>
     
    </>
  );
};

export default CustomDataTable;
