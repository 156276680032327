import React from "react";
import { connect } from "react-redux";
import CustomDataTable from "../CustomDataTable";
import DashboardLayout from "./DashboardLayout";

class PageContent extends React.Component {
  constructor(props) {
    super(props);
  }
 
  componentDidMount() {
    this.props.getList(this.props.tokenData);
  }
  render() {
    let tableData = [];
    if (
      this.props.dataToList.data &&
      this.props.dataToList.data.data
    ) {
      tableData = this.props.dataToList.data.data;
    }

    return (
      <div className="page-content">
        <div className="container-fluid">
          <CustomDataTable
            {...this.props.tableConfig}
            tableData={tableData}
            dataToList={this.props.dataToList}
          />
        </div>
      </div>
    );
  }
}

function DashboardList({
  useAuth,
  loginData,
  getList,
  dataToList,
  tableConfig,
}) {
  const tokenData = loginData.data;
  return (
    <DashboardLayout useAuth={useAuth}>
      <PageContent
        getList={getList}
        tokenData={tokenData}
        dataToList={dataToList}
        tableConfig={tableConfig}
      />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardList);
