import {
  LOGGED_IN,
  LOGGED_OUT,
  LOGGING_IN,
  LOGIN_ERROR,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";

export const loginUser = (userCredentials, remember_me) => async (dispatch) => {
  dispatch({
    type: LOGGING_IN,
  });
  await axios
    .post(`${BASE_URL}api/v1/admin/auth/login`, {
      ...userCredentials,
      remember: remember_me,
    })
    .then(function (response) {
      dispatch({
        type: LOGGED_IN,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: error.response,
      });
    });
};

export const logoutUser = () => async (dispatch) => {
  dispatch({
    type: LOGGED_OUT,
  });
};

export const setUserLoginDataFromLocalStorage = () => async (dispatch) => {
  dispatch({
    type: LOGGED_IN,
    payload: {
      data: {
        access_token: localStorage.getItem('access_token'),
        expires_at: localStorage.getItem('expires_at'),
        token_type: localStorage.getItem('token_type'),
      }
    },
  });
}
