import React, {useEffect} from "react";
import ProgressBar from "../Components/Core/ProgressBar";
import Logo from "../assets/images/logo-light.png";
import PerfectScrollbar from 'perfect-scrollbar';
import {MenuItem, MenuDropdown, MenuDropDownItem, MenuTitle} from "../Components/Core/MenuItem";

const menuItemsAndLinks = [
  {
    type: 'dropdown',
    name: "Users",
    icon: 'bi-people-fill',
    "Users": [
      {'Add New': "/user/add"},
      {'All Users': "/users"},
    ],
  },
  {
    type: 'dropdown',
    name: "Categories",
    icon: 'bi-diagram-3',
    "Categories": [
      {'Add New': "/category/add?type=blog"},
      {'All Categories': "/categories"},
      {'Deleted Categories': "/category/deleted"},
    ],
  },
  {
    type: 'dropdown',
    name: "Articles",
    icon: 'bi-file-earmark-richtext',
    "Articles": [
      {'Add New': "/article/add"},
      {'All Articles': "/articles"},
      {'Deleted Articles': "/article/deleted"},
    ],
  },
  {
    type: 'dropdown',
    name: "Menu",
    icon: 'bi-menu-app-fill',
    "Menu": [  
      {'Manage Menu': "/menus"},
      {'Add New': "/menu/add"},   
      {'Deleted Menu': "/menu/deleted"},
    ],
  },

  {
    type: 'dropdown',
    name: "Team",
    icon: 'bi-people',
    "Team": [
      {'Add New': "/team/add"},
      {'Team Members': "/teams"},
      {'Deleted Members': "/team/deleted"},
    ],
  },

  {
    type: 'dropdown',
    name: "Testimonials",
    icon: 'bi-chat-left-quote',
    "Testimonials": [
      {'Add New': "/testimonial/add"},
      {'Testimonials List': "/testimonials"},
      {'Deleted Testimonials': "/testimonial/deleted"},
    ],
  },

  {
    type: 'dropdown',
    name: "FAQS",
    icon: 'bi-question-circle',
    "FAQS": [
      {'Add New': "/faq/add"},
      {'Faqs List': "/faqs"},
      {'Deleted Faqs': "/faq/deleted"},
    ],
  },

  {
    type: 'title',
    name: 'Blogs',
  },
  {
    type: 'dropdown',
    name: "Authors",
    icon: 'bi-person-lines-fill',
    "Authors": [
      {'Add New': "/blog/author/add"},
      {'All Authors': "/blog/authors"},
      {'Deleted Authors': "/blog/author/deleted"},
    ],
  },
  
  {
    type: 'dropdown',
    name: "Blogs",
    icon: 'bi-pencil-square',
    "Blogs": [
      {'Add New': "/blog/add"},
      {'All Blogs': "/blogs"},
      {'Deleted Blogs': "/blog/deleted"},
    ],
  },

  {
    type: 'title',
    name: 'Travel',
  },
  {
    type: 'dropdown',
    name: "Package",
    icon: 'bi-compass',
    "Package": [
      {'Add New': "/package/add"},
      {'All Packages': "/packages"},
      {'Deleted Packages': "/package/deleted"},
    ],
  },

  {
    type: 'dropdown',
    name: "Itinerary",
    icon: 'bi-geo-alt',
    "Itinerary": [
      {'Add New': "/itinerary/add"},
      {'All Itineraries': "/Itineraries"},
      {'Deleted Itineraries': "/itinerary/deleted"},
    ],
  }
]

const Sidebar = () => {

  useEffect(()=>{
    const container = document.querySelector('#sidebar-scroll-container');
    const ps = new PerfectScrollbar(container);
    
  })

 
  return (
    <aside className="sidebar dark">
      <div className="sidebar-container">
        <div className="sidebar-header">
          <a className="navbar-brand" href="#">
            <img src={Logo} alt="" />
          </a>
          <div className="data-usage">
            <ProgressBar
              percentValue={65}
              fact="4.5 / 5"
              description="progress"
            />
          </div>
        </div>

        <div className="sidebar-body" id="sidebar-scroll-container" data-perfact-scrollbar="true">
          <nav>
            <ul className="navbar-nav">
              <li className="menu-title">Menu</li>
              {
               
              }
              {menuItemsAndLinks.map((menuItem, idx) => {
                 switch (menuItem.type) {
                  case ('dropdown'):
                    return(
                      <MenuDropdown key={idx} href={menuItem[menuItem.name]} iconClass={menuItem.icon} title={menuItem.name}>
                      {menuItem[menuItem.name].map((subMenu,jdx)=> {
                        return(
                          <MenuDropDownItem key={jdx} href={subMenu[Object.keys(subMenu)[0]]} title={Object.keys(subMenu)[0]}/>
                        )
                      })}
                    </MenuDropdown>
                    )
                  case ('title'):
                    return(
                      <MenuTitle title={menuItem.name} />
                    )
                  case ('link'):
                    return(
                      <MenuItem key={idx} href={menuItem[menuItem.name]} iconClass={menuItem.icon} title={menuItem.name}/>
                    )
                }})}
                  
              
              
             
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
}
 
export default Sidebar;

