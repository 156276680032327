import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import DashboardList from "../../Components/Layouts/DashboardList";
import {getDeletedList, permanentDeleteEntity, restoretDeleteEntity} from "../../Redux/Page/actions"; 

function DeletedList({ useAuth, getList, list, onDelete, onRestore}) {
  const tableConfig = {
    title: "Deleted Article(s)",
    filterOptionTitle: "Category Type",
    filterOptions: [
      "category",
    ],
    defaultFilterValue: 'admin',
    applyFilter: false,
    tableColumns: [
      {name: "Order", selector: 'order', width: '3%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Title", selector: 'page_title', width: '70%'},
      {name: "Modified Date", selector: 'updated_at', width: '17%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'name',
    searchByColumn: 'name',
    updatePath: "/article/update", 
    addPath: "/article/add",
    restorePath: "/articles",
    noDataMessage: "No article exists",
    updateLinkColumn: 'name',
    deleteBtnText: 'Delete',
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList)
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}



const mapStateToProps = (state) => {
  return {
    list: state.article,
    loginData: state.loginData, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getDeletedList(tokenData)),
    onDelete: (idList) => dispatch(permanentDeleteEntity(idList)),
    onRestore: (idList) => dispatch(restoretDeleteEntity(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletedList);
