import React from "react";

export default function Footer() {
  const thisYear = new Date().getFullYear();
  return (
    <footer id="footer">
      <div className="container-fluid">
        <div className="footer-container">
          <div className="col-6">
            &copy; copyright {thisYear} all rights reserved
          </div>
          <div className="col-6">
            If anything? Please do not hesitate to <a href="#">contact Us</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
