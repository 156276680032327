import {
  GET_SETTINGS_LIST_ERROR,
  GET_SETTINGS_LIST,
  SETTINGS_LIST_LOADING,
  GET_SETTINGS_BY_ID_LOADING,
  GET_SETTINGS_BY_ID_SUCCESS,
  GET_SETTINGS_BY_ID_ERROR,
  DELETEING_SETTING,
  DELETE_SETTING,
  DELETE_SETTING_ERROR,  
  UPDATING_SETTING,
  SETTING_UPDATED,
  ERROR_SETTING_UPDATE,
  SET_UPDATED_RECENTLY_TO_FALSE
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETTINGS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_SETTINGS_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_SETTING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UPDATING_SETTING: 
      return{
        ...state,
        loading: true,
        error: false
      }

    case GET_SETTINGS_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_SETTINGS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };

    case DELETE_SETTING:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case SETTING_UPDATED: 
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false
      }

    case GET_SETTINGS_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_SETTINGS_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };

    case DELETE_SETTING_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    case ERROR_SETTING_UPDATE: 
      return{
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data
      }
      case SET_UPDATED_RECENTLY_TO_FALSE:
        return {
          ...state,
          loadedRecently: false
        }

    default:
      return state;
  }
};

export default reducer;
