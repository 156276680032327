import {
  CREATING_PACKAGE,
  UPDATING_PACKAGE,
  PACKAGE_CREATED,
  PACKAGE_UPDATED,
  ERROR_PACKAGE_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
}

export const createEntity = (formData, tokenData, exitNow, history) => async (dispatch) => {
  dispatch({
    type: CREATING_PACKAGE,
  });

  let data = Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != null).filter(([_, v]) => v !== "").filter(([_, v]) => v !== undefined));
  let webKitFormData = new FormData();
  
  if(data.categories){ 
    for(let i = 0; i < data.categories.length; i++) {
      webKitFormData.append('categories[]', data.categories[i])
    }
  }
  if(data.banners){ 
    let bannerImage = data.banners.map(item => item.banner_image)
    let bannerCaption = data.banners.map(item => item.banner_caption)
    let bannerAlt = data.banners.map(item => item.banner_alt)
    for(let i = 0; i < bannerImage.length; i++) {
      webKitFormData.append('banners['+i+'].banner_image', bannerImage[i])
    }
    for(let i = 0; i < bannerCaption.length; i++) {
      webKitFormData.append('banners['+i+'].banner_caption', bannerCaption[i])
    }
    for(let i = 0; i < bannerAlt.length; i++) {
      webKitFormData.append('banners['+i+'].banner_alt', bannerAlt[i])
    }
  }
  if(data.facts){ 
    let factsTitle = data.facts.map(item => item.fact_title)
    let factsValue = data.facts.map(item => item.fact_value)
    for(let i = 0; i < factsTitle.length; i++) {
      webKitFormData.append('facts['+i+'].fact_title', factsTitle[i])
    }
    for(let i = 0; i < factsValue.length; i++) {
      webKitFormData.append('facts['+i+'].fact_value', factsValue[i])
    }
  }
  if(data.departures){ 
    let departureDate = data.departures.map(item => item.departure_date)
    let departureCost = data.departures.map(item => item.departure_cost)
    let departureNote = data.departures.map(itm => itm.departure_note)
    let departureStatus = data.departures.map(itm => itm.departure_status)
    for(let i = 0; i < departureDate.length; i++) {
      webKitFormData.append('departures['+i+'].departure_date', departureDate[i])
    }
    for(let i = 0; i < departureNote.length; i++) {
      webKitFormData.append('departures['+i+'].departure_note', departureNote[i])
    }
    for(let i = 0; i < departureStatus.length; i++) {
      webKitFormData.append('departures['+i+'].departure_status', departureStatus[i])
    }    
    for(let i = 0; i < departureCost.length; i++) {
      webKitFormData.append('departures['+i+'].departure_cost', departureCost[i])
    }
  }
  
  ['departures', 'facts', 'categories', 'banners'].forEach(e => delete data[e]);

  Object.keys(data).forEach((item, i) => {
    webKitFormData.append(item, data[item]) 
  });



  console.log(webKitFormData)
  await axios 
    .post(`${BASE_URL}api/v1/admin/travel/package/add`, webKitFormData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: PACKAGE_CREATED,
        payload: response,
      });
      dispatch({
        type: UPDATED_RECENTLY,
      });      
      message.success(response.data.message);
      if (exitNow) {
        history.push("/packages")
      }
      
    })
    .catch(function (error) {
      dispatch({
        type: ERROR_PACKAGE_CREATE,
        payload: error.response,
      })      
      message.error(error.response.data.message);
    });
};


export const updateEntityInfo = (formData, tokenData, id) => async (dispatch) => {
  dispatch({
    type: UPDATING_PACKAGE,
  });
  
  let data = Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != null).filter(([_, v]) => v !== "").filter(([_, v]) => v !== undefined));
  let webKitFormData = new FormData();
  if (data.featured_image && (typeof data.featured_image === 'string')) {
    delete data.featured_image
  } else if (data.featured_image && (typeof data.featured_image === 'object')) {
    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item])
    });
    webKitFormData.append('_method', 'put')
  }

  await axios
    .post(`${BASE_URL}api/v1/admin/travel/package/edit/${id}`, typeof formData.featured_image === 'object' ? webKitFormData : {...formData, "_method": 'put'} , {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: PACKAGE_UPDATED,
        payload: response,
      });
      dispatch({
        type: UPDATED_RECENTLY,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: ERROR_PACKAGE_CREATE,
        payload: error.response,
      })      
      message.error(error.response.data.message);
    });
};
