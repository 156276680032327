import {
  AUTHOR_LIST_LOADING,
  GET_AUTHOR_LIST_ERROR,
  GET_AUTHOR_LIST,
  GET_AUTHOR_BY_ID_LOADING,
  GET_AUTHOR_BY_ID_SUCCESS,
  GET_AUTHOR_BY_ID_ERROR,
  DELETEING_AUTHOR,
  DELETE_AUTHOR,
  DELETE_AUTHOR_ERROR,
  DELETED_AUTHOR_LIST_LOADING,
  GET_DELETED_AUTHOR_LIST,
  GET_DELETED_AUTHOR_LIST_ERROR,
  PERMA_DELETEING_AUTHOR,
  PERMA_DELETE_AUTHOR,
  PERMA_DELETE_AUTHOR_ERROR,
  RESTORE_AUTHOR_LIST_LOADING,
  RESTORE_AUTHOR_LIST,
  RESTORE_AUTHOR_LIST_ERROR,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHOR_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_AUTHOR_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_AUTHOR:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_AUTHOR_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case PERMA_DELETEING_AUTHOR:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case RESTORE_AUTHOR_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_AUTHOR_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_AUTHOR_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
    case GET_DELETED_AUTHOR_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case DELETE_AUTHOR:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case PERMA_DELETE_AUTHOR:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case RESTORE_AUTHOR_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_AUTHOR_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_AUTHOR_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DELETED_AUTHOR_LIST_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_AUTHOR_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case PERMA_DELETE_AUTHOR_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case RESTORE_AUTHOR_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;