import React from "react";
import Avatar from "../assets/images/avatar.jpg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { logoutUser } from "../Redux/UserAuth/actions";
import { connect } from "react-redux";

function Toolbar({ toggleSidebar, logoutUser, useAuth, sidebarOpen }) {
  let history = useHistory();
  let auth = useAuth();
  const logged_in_user = auth.user;

  return (
    <aside className="toolbar dark">
      <div className="toolbar-container">
        <nav>
          <ul>
            <li>
              <Link className={`sidebar-toggler ${sidebarOpen? ' in ':''}`} onClick={() => toggleSidebar()}>
                <div className="toggle-btn">
                  <span>Menu</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/dashboard">
                <i className="bi bi-house-door"></i>
              </Link>
            </li>
          </ul>
        </nav>
        <nav>
          <ul>
            <li className="user-profile active">
              <a href="#">
                <img src={Avatar} alt="avatar" />
              </a>
            </li>
            <li>
              <Link to="/settings">
                <i className="bi bi-gear"></i>
              </Link>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-bell"></i>
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => {
                  logoutUser();
                  auth.signout(() => history.push("/dashboard"));
                }}
              >
                <i className="bi bi-power"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
