import React,{useState, useEffect} from 'react'
import {BASE_URL} from "../../Redux/urls";

const ImageField = ({image, setImage, title}) => {
  const onChange = (e) => {
    e.preventDefault();
    setImage(e.target.files[0])
  };
  let [imageToDisplay,setImageToDisplay] = useState();
  let reader = new FileReader();

  if (image && (typeof image === 'string')) {
    if (image.indexOf('http') !== -1) {
      imageToDisplay = image
    } else {
      imageToDisplay = BASE_URL + image
    }
  } else if (image && (typeof image === 'object')) {
    //reader.readAsDataURL(image)
    reader.onload = () => {
      imageToDisplay = reader.result;
    };
  }

  useEffect(()=>{
    let reader = new FileReader();
    if (image && (typeof image === 'string')) {
      setImageToDisplay(BASE_URL + image)
    } else if (image && (typeof image === 'object')) {
      reader.readAsDataURL(image)
      reader.onload = () => {
        setImageToDisplay(reader.result)
      };
    }
  }, [image])


  return(
    <>
          <h6>{title}</h6>
            <label
              className="thumbinal-image"
              id="thumbinal_image"
              data-height="300"
              data-width="400"
            >
              <figure>
                <img id="item-img-output" src={imageToDisplay} height="300" width="400" />
                <figcaption>
                  <i className="bi bi-camera-fill"></i>
                </figcaption>
              </figure>
              <input
                type="file"
                image={image}
                className="item-img file center-block"
                id="thumbinalInput"
                hidden
                onChange={onChange}
              />
            </label>
    
    </>
  )
}

export default ImageField
