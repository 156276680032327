import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Switch,
  message,
  TreeSelect,
  Alert
} from "antd";

import { getList as getMenuTypeList } from "../../Redux/MenuType/actions";
import { getList as getMenuList } from "../../Redux/Menu/actions";
import DashboardActionBar from "../DashboardActionBar";

const { Option } = Select;
const { TreeNode } = TreeSelect;

const MenuForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getMenuTypeList,
  menuTypeList,
  getMenuList,
  menuList,
}) => {
  let history = useHistory();
  const [errors, showErrors] = useState({});
  const tokenData = loginData.data;
  const [form] = Form.useForm();
  const { validateFields, setFieldsValue, getFieldValue } = form;
  const loading = selectedForm.loading || data.loading;

  const loadMenu = async () => {
    await getMenuList(tokenData);
  };
  const loadMenuType = async () => {
    await getMenuTypeList(tokenData);
  };

  useEffect(() => {
    if (isUpdateForm) {
      getEntityById(tokenData, params.id);
    }
    if (!menuTypeList.data) {
      loadMenuType();
    }
    if (!menuList.data) {
      loadMenu();
    }
  }, [menuTypeList.data, menuList.data]);

  const menuTypeAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            is_external: values.status ? 1 : 0,
          },
          tokenData
        ).then(() => {
          loadMenu();
        });
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            is_external: values.status ? 1 : 0,
          },
          tokenData,
          true,
          history
        ).then(() => {
          loadMenu();
        });
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            is_external: values.status ? 1 : 0,
          },
          tokenData,
          params.id
        ).then(() => {
          loadMenu();
        });
      }
    });
  };

  const addNewEntry = (e) => {
    menuTypeAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    menuTypeAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    menuTypeAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
  };


  const actionBarOptions = {
    titleToAdd: "Add Menu",
    titleToUpdate: "Update Menu",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Menu",
    taskCompleteAddMessage: "Menu is added!",
    taskCompleteUpdateMessage: "Menu is updated!",
    discardLink: "menus",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };
  const menuTypeListOptions = menuTypeList.data?.data.map((itm) => {
    return {
      label: itm.type_title,
      value: itm.id.toString(),
    };
  });

  const menuListOptions = menuList.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.item_title,
      ...(itm.menu?.length !== 0 && {
        menu: itm.menu?.map((a) => {
          return {
            value: a.id,
            label: a.item_title,
            ...(a.menu?.length !== 0 && {
              menu: a.menu?.map((b) => {
                return {
                  value: b.id,
                  label: b.item_title,
                };
              }),
            }),
          };
        }),
      }),
    };
  });

  
  if (isUpdateForm) {
    setFieldsValue({
      type_id: data.dataById?.data.type_id,
      parent_id: data.dataById?.data.parent_id,
      is_external: data.dataById?.data.is_external == 1 ? true : false,
      item_title: data.dataById?.data.item_title,
      external_link: data.dataById?.data.external_link,
      url_segment: data.dataById?.data.url_segment,
    });
  }

  return (
    <Form
      form={form}
      size="large"
      layout="vertical"
      className="custom-form"
      wrapperCol={{
        flex: 1,
      }}
    >
      <DashboardActionBar {...{ actionBarOptions }} />

      {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

      <div className="common-module">
        <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
          <Col className="gutter-row" xs={24} sm={12} xl={8}>
            <Form.Item
              label="Title"
              name="item_title"
              rules={[
                {
                  required: true,
                  message: "Title is required",
                },
                { min: 3, max: 255, message: "Invalid Title" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12} xl={8}>
            <Form.Item
              label="Type"
              name="type_id"
              rules={[
                {
                  required: true,
                  message: "Type is required",
                },
              ]}
            >
              <Select>
                {menuTypeListOptions?.map((itm, idx) => {
                  return (
                    <Option key={idx} value={itm.value}>
                      {itm.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12} xl={8}>
            <Form.Item
              label="Parent Menu"
              name="parent_id"
              rules={[
                {
                  required: true,
                  message: "Parent is required",
                },
              ]}
            >
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Please select"
                allowClear
                treeDefaultExpandAll={true}
              >
                <TreeNode value="0" title="No parent" />
                {menuListOptions?.map((parent) => {
                  const { value, label, children } = parent;
                  return (
                    <TreeNode key={value} value={value} title={label}>
                      {children?.length !== "0" &&
                        children?.map((child) => {
                          const { value, label, children } = child;
                          return (
                            <TreeNode key={value} value={value} title={label}>
                              {children?.length !== "0" &&
                                children?.map((gChild) => {
                                  const { value, label } = gChild;
                                  return (
                                    <TreeNode
                                      key={value}
                                      value={value}
                                      title={label}
                                    />
                                  );
                                })}
                            </TreeNode>
                          );
                        })}
                    </TreeNode>
                  );
                })}
              </TreeSelect>
            </Form.Item>
          </Col>

          <Col className="gutter-row" xs={24} sm={12} xl={8}>
            <Form.Item
              label="Menu Type Key"
              name="type_key"
              rules={[
                {
                  required: true,
                  message: "Please input your type key",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.is_external !== currentValues.is_external
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("is_external") === true ? (
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label="External link"
                    name="external_link"
                    rules={[
                      {
                        required: true,
                        message: "Please input your url",
                      },
                      { type: "url", warningOnly: true },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              ) : null
            }
          </Form.Item>

          <Col className="gutter-row" xs={24} sm={12} xl={8}>
            <div className="switch-group">
              <Form.Item
                label="Is external link?"
                valuePropName="checked"
                name="is_external"
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    menuList: state.menu,
    menuTypeList: state.menuType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenuTypeList: (tokenData) => dispatch(getMenuTypeList(tokenData)),
    getMenuList: (tokenData) => dispatch(getMenuList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuForm);
