export const SETTINGS_LIST_LOADING = "SETTINGS_LIST_LOADING";
export const GET_SETTINGS_LIST = "GET_SETTINGS_LIST";
export const GET_SETTINGS_LIST_ERROR = "GET_SETTINGS_LIST_ERROR";
export const GET_SETTINGS_BY_ID_LOADING = "GET_SETTINGS_BY_ID_LOADING";
export const GET_SETTINGS_BY_ID_SUCCESS = "GET_SETTINGS_BY_ID_SUCCESS";
export const GET_SETTINGS_BY_ID_ERROR = "GET_SETTINGS_BY_ID_ERROR";
export const DELETEING_SETTING = "DELETEING_SETTING";
export const DELETE_SETTING = "DELETE_SETTING";
export const DELETE_SETTING_ERROR = "DELETE_SETTING_ERROR";
export const UPDATING_SETTING = "UPDATING_SETTING";
export const SETTING_UPDATED = "SETTING_UPDATED";
export const ERROR_SETTING_UPDATE = "ERROR_SETTING_UPDATE";
export const SET_UPDATED_RECENTLY_TO_FALSE = "SET_UPDATED_RECENTLY_TO_FALSE"
