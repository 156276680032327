import React from "react";
import Toolbar from "../../Components/Toolbar";
import Sidebar from "../../Components/Sidebar";
import NavBarDashboard from "../../Components/NavBarDashboard";
import Footer from "../../Components/Footer";

export default function DashboardLayout({ useAuth, children }) {
  const [sidebar_active, setSidebarActive] = React.useState(false);

  return (
    <div id="wrapper">
      <div
        className={`contain-wrapper ${sidebar_active ? "sidebar-active" : ""}`}
      >
        <Toolbar
          toggleSidebar={() => setSidebarActive(!sidebar_active)}
          sidebarOpen={sidebar_active}
          useAuth={useAuth}
        />
        <Sidebar />
        <div className="page-wrapper">
          <NavBarDashboard />
          {children}
          <Footer />
        </div>
      </div>
    </div>
  );
}
