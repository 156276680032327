import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RoundedSubmitButton } from "./Core/Button";import {
  message,
} from "antd";

const DashboardActionBar = ({ actionBarOptions }) => {
  let history = useHistory();
  useEffect(() => {
    const actionBar = document.querySelector(".action-bar");
    if (actionBar) {
      const actionBarOffsetTop = actionBar.offsetTop;
      actionBar.parentElement.style.minHeight = actionBar.offsetHeight + "px";
      window.onscroll = () => {
        if (window.scrollY > actionBarOffsetTop) {
          actionBar.parentElement.classList.add("fixed-position");
        } else {
          actionBar.parentElement.classList.remove("fixed-position");
        }
      };
    }
  });
  if (
    actionBarOptions.selectedForm?.loadedRecently &&
    actionBarOptions.isAddForm
  ) {
    actionBarOptions.clearForm();
  }
  if (actionBarOptions.selectedForm?.loadedRecently) {
    setTimeout(() => {
      actionBarOptions.setUpdatedRecentlyToFalse();
    }, actionBarOptions.taskCompleteMessageDisplayTime);
  }

  const status = actionBarOptions.selectedForm.error ? 'warning' : 'success' 
  return (
    <div className="common-module mb-2">
      <div className="action-bar">
        <div className="title">
          {actionBarOptions.isAddForm && <h1>{actionBarOptions.titleToAdd}</h1>}
          {actionBarOptions.isUpdateForm && (
            <h1>{actionBarOptions.titleToUpdate}</h1>
          )}
        </div>

        <div className="action-slot">
          <ul>
            {actionBarOptions.loading && (
              <li>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              </li>
            )}
              <li>
              {actionBarOptions.isAddForm && (
                <RoundedSubmitButton
                  disabled={actionBarOptions.loading}
                  htmlType="submit"
                  className="btn btn-primary"                  
                  onClick={actionBarOptions.addNewEntryAndExistToList}
                  id="add_new"
                >
                  <i className="bi bi-save2"></i>Save
                </RoundedSubmitButton>
              )}
            </li>
            <li>
              {actionBarOptions.isAddForm && (
                <RoundedSubmitButton
                  disabled={actionBarOptions.loading}
                  className="btn btn-success"
                  id="add_new"
                  onClick={actionBarOptions.addNewEntry}
                >
                  
                  <i className="bi bi-plus"></i>
                  {actionBarOptions.addButtonLabel}
                </RoundedSubmitButton>
              )}
              {actionBarOptions.isUpdateForm && (
                <RoundedSubmitButton
                  disabled={actionBarOptions.loading}
                  className="btn btn-primary"
                  onClick={actionBarOptions.updateExistingEntry}
                  id="add_new"
                >
                  <i className="bi bi-save"></i>
                  {actionBarOptions.updateButtonLabel}
                </RoundedSubmitButton>
              )}
            </li>
          
            <li>
              <a
                className="btn btn-danger"
                id="add_new"
                onClick={() => history.push(`/${actionBarOptions.discardLink}`)}
              >
                <i className="bi bi-x"></i>Discard
              </a>
            </li>
          </ul>
        </div>
        { //actionBarOptions?.selectedForm?.loadedRecently &&  message.status(actionBarOptions?.selectedForm)
          actionBarOptions?.selectedForm?.data?.error && actionBarOptions?.selectedForm?.data?.loadedRecently && (message.success(actionBarOptions?.selectedForm?.data?.message)) 
        }
      </div>
    </div>
  );
};

export default DashboardActionBar;
