import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import PackageForm from "../../Components/Forms/PackageForm";
import { createEntity, updateEntityInfo, setUpdatedRecentlyToFalse } from "../../Redux/PackageForm/actions";
import { getEntityById } from "../../Redux/Package/actions";

function Update({useAuth, getEntityById, createEntity, updateEntityInfo, setUpdatedRecentlyToFalse, data, loginData, selectedForm}) {
  let params = useParams();
  
  return (
    <DashboardUpdate useAuth={useAuth}>
        <PackageForm
          isUpdateForm
          {...{params, getEntityById, createEntity, updateEntityInfo, setUpdatedRecentlyToFalse, data, loginData, selectedForm}}
        />
    </DashboardUpdate>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.packages,
    loginData: state.loginData,
    selectedForm: state.packageForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEntityById: (tokenData, id) => dispatch(getEntityById(tokenData, id)),
    createEntity: (formData, tokenData) => dispatch(createEntity(formData, tokenData)),
    updateEntityInfo: (formData, tokenData, id) => dispatch(updateEntityInfo(formData, tokenData, id)),
    setUpdatedRecentlyToFalse: () => dispatch(setUpdatedRecentlyToFalse()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Update);
