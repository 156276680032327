export const PAGE_LIST_LOADING = "PAGE_LIST_LOADING";
export const GET_PAGE_LIST = "GET_PAGE_LIST";
export const GET_PAGE_LIST_ERROR = "GET_PAGE_LIST_ERROR";

export const GET_PAGE_BY_ID_LOADING = "GET_PAGE_BY_ID_LOADING";
export const GET_PAGE_BY_ID_SUCCESS = "GET_PAGE_BY_ID_SUCCESS";
export const GET_PAGE_BY_ID_ERROR = "GET_PAGE_BY_ID_ERROR";

export const DELETEING_PAGE = "DELETEING_PAGE";
export const DELETE_PAGE = "DELETE_PAGE";
export const DELETE_PAGE_ERROR = "DELETE_PAGE_ERROR";

export const DELETED_PAGE_LIST_LOADING = 'DELETED_PAGE_LIST_LOADING';
export const GET_DELETED_PAGE_LIST= 'GET_DELETED_PAGE_LIST';
export const GET_DELETED_PAGE_LIST_ERROR = 'GET_DELETED_PAGE_LIST_ERROR';

export const PERMA_DELETEING_PAGE = 'PERMA_DELETEING_PAGE';
export const PERMA_DELETE_PAGE = 'PERMA_DELETE_PAGE';
export const PERMA_DELETE_PAGE_ERROR = 'PERMA_DELETE_PAGE_ERROR';

export const RESTORE_PAGE_LIST_LOADING = 'RESTORE_PAGE_LIST_LOADING';
export const RESTORE_PAGE_LIST = 'RESTORE_PAGE_LIST';
export const RESTORE_PAGE_LIST_ERROR = 'RESTORE_PAGE_LIST_ERROR';
