import {
  CREATING_AUTHOR,
  UPDATING_AUTHOR,
  AUTHOR_CREATED,
  AUTHOR_UPDATED,
  ERROR_AUTHOR_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
};

export const createEntity =
  (formData, tokenData, exitNow, history) => async (dispatch) => {
    dispatch({
      type: CREATING_AUTHOR,
    });
    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );
    let webKitFormData = new FormData();
    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });

    await axios
      .post(`${BASE_URL}api/v1/admin/blog/author/add`, webKitFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: AUTHOR_CREATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        if (exitNow) {
          history.push("/blog/authors");
        }
        message.success(response.data.message);
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({
          type: ERROR_AUTHOR_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

export const updateEntityInfo =
  (formData, tokenData, id) => async (dispatch) => {
    dispatch({
      type: UPDATING_AUTHOR,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );

    let webKitFormData = new FormData();
    if (data.avatar && typeof data.avatar === "string") {
      delete data.avatar;
    } else if (data.avatar && typeof data.avatar === "object") {
      Object.keys(data).forEach((item, i) => {
        webKitFormData.append(item, data[item]);
      });
      webKitFormData.append("_method", "put");
    }
    await axios
      .post(
        `${BASE_URL}api/v1/admin/blog/author/edit/${id}`,
        typeof formData.avatar === "object"
          ? webKitFormData
          : { ...formData, _method: "put" },
        {
          headers: {
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then(function (response) {
        dispatch({
          type: AUTHOR_UPDATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_AUTHOR_CREATE,
          payload: error.response,
        });
        message.success(error.response.data.message);
      });
  };
