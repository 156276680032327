import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DashboardLayout from "../../Components/Layouts/DashboardLayout";
import {
  getList,
  deleteEntityById,
  updateEntityById,
  setUpdatedRecentlyToFalse,
} from "../../Redux/Settings/actions";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Avatar from "../../Components/Core/Upload/Avatar";

import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
//tabs
import { Tabs, Tab } from "react-bootstrap";

const { Option } = Select;

function List({
  useAuth,
  loginData,
  getList,
  list,
  onDelete,
  updateEntityById,
}) {
  const tokenData = loginData.data;
  const [listData, setListData] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const { validateFields, setFieldsValue, getFieldValue } = form;

  const loadGetList = async () => {
    await getList(tokenData);
  };

  useEffect(() => {
    if (!list.data) {
      loadGetList();
    }
    if (list.data) {
      setListData(list.data.data);
    }
  }, [list.data]);

  const ValueType = (props) => {
    switch (props.types) {
      case "string":
        return <Input {...props} />;
      case "text":
        return <TextArea {...props}></TextArea>;
      case "integer":
        return <InputNumber {...props} />;
      case "decimal":
        return <InputNumber {...props} />;
      case "file":
        return <input type="file" {...props} />;
      case "image":
        return <input type="file" {...props} accept="image/*" />;
      case "date":
        return <DatePicker {...props} />;
    }
  };

  const SettingsComponents = ({ data, idx }) => {
    const {
      id,
      key_label,
      value_type,
      global_key,
      setting_key,
      setting_value,
      description,
      updated_at,
    } = data;

    const handleOnchanged = (values) => {
      setTimeout(() => {
        updateEntityById({ ...values }, tokenData).then(() => loadGetList());
      }, 50000);
    };
    return (
      <li key={idx}>
        <div className="col-2">
          <h4>{key_label}</h4>
          <span className="small">{description}</span>
        </div>
        <div className="col-6">
          <div className="field-wrapper custom-form">
            {value_type === "image" ? (
              <Avatar />
            ) : (
              <ValueType
                types={value_type}
                validateStatus="validating"
                defaultValue={setting_value}
                onBlur={(e) =>
                  handleOnchanged({
                    setting_value: e.target.value,
                    setting_key: global_key,
                  })
                }
              />
            )}
          </div>
        </div>
        <div className="col-2 update">
          <span className="badge bg-light-warning rounded-pill">
            <Moment fromNow ago>
              {updated_at}
            </Moment>{" "}
            &nbsp;ago
          </span>
        </div>
        <div className="col-2 action">
          <Link
            to={`/setting/update/${id}`}
            type="button"
            className="btn btn-link edit"
          >
            <i className="bi-pencil-square"></i>
          </Link>
          <button
            type="button"
            className="btn btn-link delete"
            onClick={() => onDelete(id)}
          >
            <i className="bi-trash"></i>
          </button>
        </div>
      </li>
    );
  };

  const companySettingsData = listData?.filter(
    (itm) => itm.key_group === "company"
  );
  const imagesSettingsData = listData?.filter(
    (itm) => itm.key_group === "images"
  );
  const advanceSettingsData = listData?.filter(
    (itm) => itm.key_group === "advanced"
  );

  return (
    <DashboardLayout useAuth={useAuth}>
      <div className="page-content">
        <div className="container-fluid">
          <div className="common-module">
            <div className="action-bar">
              <div className="title">
                <h1>Settings</h1>
              </div>
              <div className="action-slot">
                <ul>
                  <li>
                    <Link
                      to="/setting/add"
                      className="btn btn-primary"
                      id="add_new"
                    >
                      <i className="bi-plus-circle"></i> Add New
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="common-module mb-2">
            <Tabs
              defaultActiveKey="site"
              transition={false}
              id="noanim-tab-example"
              className="mb-3 custom-tab"
            >
              <Tab eventKey="site" title="Site Settings">
                <div className="settings-list">
                  {list.loading && (
                    <div className="text-center">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </div>
                  )}
                  <ul>
                    {companySettingsData && companySettingsData.length >= 1 ? (
                      companySettingsData?.map((itm, idx) => {
                        return <SettingsComponents key={idx} data={itm} />;
                      })
                    ) : list.loading === false ? (
                      <div className="px-3">No data exists!</div>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </Tab>
              <Tab eventKey="images" title="Images">
                <div className="settings-list">
                  {list.loading && (
                    <div className="text-center">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </div>
                  )}
                  <ul>
                    {imagesSettingsData && imagesSettingsData.length >= 1 ? (
                      imagesSettingsData.map((itm, idx) => {
                        return <SettingsComponents key={idx} data={itm} />;
                      })
                    ) : list.loading === false ? (
                      <div className="px-3">No data exists!</div>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </Tab>
              <Tab eventKey="advance" title="Advance Settings">
                <div className="settings-list">
                  {list.loading && (
                    <div className="text-center">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </div>
                  )}
                  <ul>
                    {advanceSettingsData && advanceSettingsData.length >= 1 ? (
                      advanceSettingsData.map((itm, idx) => {
                        return <SettingsComponents key={idx} data={itm} />;
                      })
                    ) : list.loading === false ? (
                      <div className="px-3">No data exists!</div>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.settings,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    updateEntityById: (formData, tokenData, id) =>
      dispatch(updateEntityById(formData, tokenData, id)),
    onDelete: (id) => dispatch(deleteEntityById(id)),
    setUpdatedRecentlyToFalse: () => dispatch(setUpdatedRecentlyToFalse()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
