export const AUTHOR_LIST_LOADING = "AUTHOR_LIST_LOADING";
export const GET_AUTHOR_LIST = "GET_AUTHOR_LIST";
export const GET_AUTHOR_LIST_ERROR = "GET_AUTHOR_LIST_ERROR";

export const GET_AUTHOR_BY_ID_LOADING = "GET_AUTHOR_BY_ID_LOADING";
export const GET_AUTHOR_BY_ID_SUCCESS = "GET_AUTHOR_BY_ID_SUCCESS";
export const GET_AUTHOR_BY_ID_ERROR = "GET_AUTHOR_BY_ID_ERROR";

export const DELETEING_AUTHOR = "DELETEING_AUTHOR";
export const DELETE_AUTHOR = "DELETE_AUTHOR";
export const DELETE_AUTHOR_ERROR = "DELETE_AUTHOR_ERROR";

export const DELETED_AUTHOR_LIST_LOADING = 'DELETED_AUTHOR_LIST_LOADING';
export const GET_DELETED_AUTHOR_LIST= 'GET_DELETED_AUTHOR_LIST';
export const GET_DELETED_AUTHOR_LIST_ERROR = 'GET_DELETED_AUTHOR_LIST_ERROR';

export const PERMA_DELETEING_AUTHOR = 'PERMA_DELETEING_AUTHOR';
export const PERMA_DELETE_AUTHOR = 'PERMA_DELETE_AUTHOR';
export const PERMA_DELETE_AUTHOR_ERROR = 'PERMA_DELETE_AUTHOR_ERROR';

export const RESTORE_AUTHOR_LIST_LOADING = 'RESTORE_AUTHOR_LIST_LOADING';
export const RESTORE_AUTHOR_LIST = 'RESTORE_AUTHOR_LIST';
export const RESTORE_AUTHOR_LIST_ERROR = 'RESTORE_AUTHOR_LIST_ERROR';
