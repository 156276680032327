import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Col,
  Row,
  Switch,
  Alert,
} from "antd";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import DashboardActionBar from "../DashboardActionBar";
const FaqsForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  let history = useHistory();
  const tokenData = loginData.data;
  const [form] = Form.useForm();
  const { validateFields, setFieldsValue, getFieldValue } = form;

  const [answer, setAnswer] = useState(
    isUpdateForm && data.dataById && data.dataById.data.type_description
  );

  const loading = selectedForm.loading || data.loading;

  useEffect(() => {
    if (isUpdateForm) {
      getEntityById(tokenData, params.id);
    }
  }, []);

  const faqsAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData
        )
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          true,
          history
        )
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          params.id
        )
      }
    });
  };

  const addNewEntry = (e) => {
    faqsAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    faqsAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    faqsAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      question: data.dataById?.data.question,
      url_title: data.dataById?.data.url_title,
      slug: data.dataById?.data.slug,
      title: data.dataById?.data.type_title,
      type_key: data.dataById?.data.type_key,
      status: data.dataById?.data.status == 1 ? true : false,
      answer: data.dataById?.data.answer,
      meta_title: data.dataById?.data.meta_title,
      meta_keywords: data.dataById?.data.meta_keywords,
      meta_description: data.dataById?.data.meta_description,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add FAQ",
    titleToUpdate: "Update FAQ",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update FAQ",
    taskCompleteAddMessage: "FAQ is added!",
    taskCompleteUpdateMessage: "FAQ is updated!",
    discardLink: "faqs",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <Form
      form={form}
      size="large"
      layout="vertical"
      className="custom-form"
      wrapperCol={{
        flex: 1,
      }}
    >
      <DashboardActionBar {...{ actionBarOptions }} />

      {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
      <div className="common-module">
        <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
          <Col className="gutter-row" xs={24} sm={12} xl={12}>
            <Form.Item
              label="Question"
              name="question"
              rules={[
                {
                  required: true,
                  message: "question is required",
                },
                { min: 3, max: 255, message: "Invalid question" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12} xl={8}>
            <div className="switch-group">
              <Form.Item label="Status" valuePropName="checked" name="status">
                <Switch
                  checkedChildren="Publish"
                  unCheckedChildren="Unpublish"
                />
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" xs={24}>
            <Form.Item label="Answer" name="answer">
              <TextEditor
                value={answer}
                initialValue={isUpdateForm && answer}
                onEditorChange={(e) => {
                  setAnswer(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default FaqsForm;
