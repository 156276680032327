export const FAQS_LIST_LOADING = "FAQS_LIST_LOADING";
export const GET_FAQS_LIST = "GET_FAQS_LIST";
export const GET_FAQS_LIST_ERROR = "GET_FAQS_LIST_ERROR";

export const GET_FAQS_BY_ID_LOADING = "GET_FAQS_BY_ID_LOADING";
export const GET_FAQS_BY_ID_SUCCESS = "GET_FAQS_BY_ID_SUCCESS";
export const GET_FAQS_BY_ID_ERROR = "GET_FAQS_BY_ID_ERROR";

export const DELETEING_FAQS = "DELETEING_FAQS";
export const DELETE_FAQS = "DELETE_FAQS";
export const DELETE_FAQS_ERROR = "DELETE_FAQS_ERROR";

export const DELETED_FAQS_LIST_LOADING = 'DELETED_FAQS_LIST_LOADING';
export const GET_DELETED_FAQS_LIST= 'GET_DELETED_FAQS_LIST';
export const GET_DELETED_FAQS_LIST_ERROR = 'GET_DELETED_FAQS_LIST_ERROR';

export const PERMA_DELETEING_FAQS = 'PERMA_DELETEING_FAQS';
export const PERMA_DELETE_FAQS = 'PERMA_DELETE_FAQS';
export const PERMA_DELETE_FAQS_ERROR = 'PERMA_DELETE_FAQS_ERROR';

export const RESTORE_FAQS_LIST_LOADING = 'RESTORE_FAQS_LIST_LOADING';
export const RESTORE_FAQS_LIST = 'RESTORE_FAQS_LIST';
export const RESTORE_FAQS_LIST_ERROR = 'RESTORE_FAQS_LIST_ERROR';
