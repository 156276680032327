import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import RouteAuth from "./Components/RouteAuth";
import Dashboard from "./Containers/Dashboard";
import Login from "./Containers/Login";

import UserAdd from "./Containers/User/Add";
import UserUpdate from "./Containers/User/Update";
import UserList from "./Containers/User/List";

import SettingAdd from "./Containers/Settings/Add";
import SettingUpdate from "./Containers/Settings/Update";
import SettingsList from "./Containers/Settings/List";

import AuthorAdd from "./Containers/Author/Add";
import AuthorUpdate from "./Containers/Author/Update";
import AuthorList from "./Containers/Author/List";
import DeletedAuthor from "./Containers/Author/DeletedList";

import PageAdd from "./Containers/Page/Add";
import PageUpdate from "./Containers/Page/Update";
import PageList from "./Containers/Page/List";
import DeletedPage from "./Containers/Page/DeletedList";

import TeamAdd from "./Containers/Team/Add";
import TeamUpdate from "./Containers/Team/Update";
import TeamList from "./Containers/Team/List";
import DeletedTeam from "./Containers/Team/DeletedList";

import TestimonialAdd from "./Containers/Testimonial/Add";
import TestimonialUpdate from "./Containers/Testimonial/Update";
import TestimonialList from "./Containers/Testimonial/List";
import DeletedTestimonial from "./Containers/Testimonial/DeletedList";

import FaqsAdd from "./Containers/Faqs/Add";
import FaqsUpdate from "./Containers/Faqs/Update";
import FaqsList from "./Containers/Faqs/List";
import DeletedFaqs from "./Containers/Faqs/DeletedList";

import CategoryType from "./Containers/Category/CategoryType";
import CategoryTypeAdd from "./Containers/Category/CategoryTypeAdd";
import CategoryTypeUpdate from "./Containers/Category/CategoryTypeUpdate";
import DeletedCategoryType from "./Containers/Category/DeletedCategoryType";
import CategoryAdd from "./Containers/Category/Add";
import CategoryUpdate from "./Containers/Category/Update";
import CategoryList from "./Containers/Category/List";
import DeletedCategory from "./Containers/Category/DeletedList";

import BlogAdd from "./Containers/Blog/Add";
import BlogUpdate from "./Containers/Blog/Update";
import BlogList from "./Containers/Blog/List";
import DeletedBlog from "./Containers/Blog/DeletedList";

//packages
import PackageAdd from "./Containers/Package/Add";
import PackageUpdate from "./Containers/Package/Update";
import PackageList from "./Containers/Package/List";
import DeletedPackage from "./Containers/Package/DeletedList";

//Itinerary
import ItineraryAdd from "./Containers/Itinerary/Add";
import ItineraryUpdate from "./Containers/Itinerary/Update";
import ItineraryList from "./Containers/Itinerary/List";
import DeletedItinerary from "./Containers/Itinerary/DeletedList";

//menu
import MenuAdd from "./Containers/Menu/Add";
import MenuUpdate from "./Containers/Menu/Update";
import MenuList from "./Containers/Menu/List";
import DeletedMenu from "./Containers/Menu/DeletedList";

//menuType
import MenuTypeAdd from "./Containers/Menu/MenuTypeAdd";
import MenuTypeUpdate from "./Containers/Menu/MenuTypeUpdate";
import MenuTypeList from "./Containers/Menu/MenuType";
import DeletedMenuType from "./Containers/Menu/DeletedMenuType";

// Redux
import { Provider } from "react-redux";
import store from "./Redux/store";

// Bootstrap, Popper and Styles
import "bootstrap";
import "@popperjs/core";
// import "bootstrap/dist/css/bootstrap.css";

// BUGGY VERSION --> import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/bootstrap-icons/font/bootstrap-icons.css";

import "./assets/scss/main.scss";
import "./index.css";


const pages_and_routes = {
  protected_pages: [
    { path: "/dashboard", Page: Dashboard },
    
    { path: "/settings", Page: SettingsList},
    { path: "/setting/add", Page: SettingAdd},
    { path: "/setting/update/:id", Page: SettingUpdate},

    { path: "/user/add", Page: UserAdd },
    { path: "/user/update/:id", Page: UserUpdate },
    { path: "/users", Page: UserList },
    
    { path: "/category-types", Page: CategoryType },
    { path: "/category-type/add", Page: CategoryTypeAdd },
    { path: "/category-type/update/:id", Page: CategoryTypeUpdate },
    { path: "/category-type/deleted", Page: DeletedCategoryType },
    { path: "/category/add", Page: CategoryAdd },
    { path: "/category/update/:id", Page: CategoryUpdate },
    { path: "/categories", Page: CategoryList },
    { path: "/category/deleted", Page: DeletedCategory },   

    { path: "/articles", Page: PageList },
    { path: "/article/update/:id", Page: PageUpdate },
    { path: "/article/add", Page: PageAdd },
    { path: "/article/deleted", Page: DeletedPage },

    { path: "/manage-menu", Page: MenuTypeList },
    { path: "/menu-type/add", Page: MenuTypeAdd },
    { path: "/menu-type/update/:id", Page: MenuTypeUpdate },    
    { path: "/menu-type/deleted", Page: DeletedMenuType },
    
    { path: "/menus", Page: MenuList},
    { path: "/menu/add", Page: MenuAdd},
    { path: "/menu/update/:id", Page: MenuUpdate },
    { path: "/menu/deleted", Page: DeletedMenu },

    { path: "/teams", Page: TeamList },
    { path: "/team/update/:id", Page: TeamUpdate },
    { path: "/team/add", Page: TeamAdd },
    { path: "/team/deleted", Page: DeletedTeam }, 
    
    { path: "/testimonials", Page: TestimonialList },
    { path: "/testimonial/update/:id", Page: TestimonialUpdate },
    { path: "/testimonial/add", Page: TestimonialAdd },
    { path: "/testimonial/deleted", Page: DeletedTestimonial }, 

    { path: "/faqs", Page: FaqsList },
    { path: "/faq/update/:id", Page: FaqsUpdate },
    { path: "/faq/add", Page: FaqsAdd },
    { path: "/faq/deleted", Page: DeletedFaqs }, 

    { path: "/blog/author/add", Page: AuthorAdd },
    { path: "/blog/author/update/:id", Page: AuthorUpdate },
    { path: "/blog/authors", Page: AuthorList },
    { path: "/blog/author/deleted", Page: DeletedAuthor }, 

    { path: "/blog/add", Page: BlogAdd },
    { path: "/blog/update/:id", Page: BlogUpdate },
    { path: "/blogs", Page: BlogList },
    { path: "/blog/deleted", Page: DeletedBlog },

    { path: "/package/add", Page: PackageAdd },
    { path: "/package/update/:id", Page: PackageUpdate },
    { path: "/packages", Page: PackageList },
    { path: "/package/deleted", Page: DeletedPackage },
    
    { path: "/itinerary/add", Page: ItineraryAdd },
    { path: "/itinerary/update/:id", Page: ItineraryUpdate },
    { path: "/itineraries", Page: ItineraryList },
    { path: "/itinerary/deleted", Page: DeletedItinerary },
  ],
  public_pages: [
    // { path: "/home", Page: PublicHome },
    // { path: "/about-us", Page: PublicAboutUs },
  ],
  AUTH_ENTRY_PAGE: { path: "/login", Page: Login },
  ROOT_PAGE: { path: "/", Page: Login },
};
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouteAuth data={pages_and_routes} />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
