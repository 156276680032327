export const USER_LIST_LOADING = "USER_LIST_LOADING";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";
export const GET_USER_BY_ID_LOADING = "GET_USER_BY_ID_LOADING";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_ERROR = "GET_USER_BY_ID_ERROR";
export const DELETED_USER_LIST_LOADING = "DELETED_USER_LIST_LOADING";
export const GET_DELETED_USER_LIST = "GET_DELETED_USER_LIST";
export const GET_DELETED_USER_LIST_ERROR = "GET_DELETED_USER_LIST_ERROR";
export const DELETEING_USER = "DELETEING_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";