import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getList } from '../Redux/Logs/actions';
import Moment from "react-moment";

const RecentUpdates = ({loginData, getList, list}) => {
    const tokenData = loginData.data;
    const [logs, setLogs] = useState();
    useEffect(()=>{
        if(!list.data){
            getList(tokenData);
        }
        if(list.data){
            setLogs(list.data.data);
        }
    }, [list.data])
    
    return (
      <div className="card bg-white">
        <div className="card-header">
          <div className="card-title">
            <h2>Recent Update</h2>
            <p>{logs&&logs.length} Acual Logs</p>
          </div>
          <a href="#" className="btn btn-link font-weight-medium text-body">
            View All Logs <i className="bi bi-chevron-double-right"></i>
          </a>
        </div>
        <div className="card-body">
          <div className="timeline">
            <ul>
              {logs&&logs.map((value, idx) => {
                return (
                  <li className={value.action_category_key} key={idx}>
                    <div className="time"> <Moment fromNow ago>{value.created_at}</Moment></div>
                    <div className="intro-img"></div>
                    <div className="intro-text">
                      <div className="col">
                        <h4>{value.action_title}</h4>
                        <ul className="meta">
                          <li>{value.logger.name}</li>
                          <li>{value.action_category}</li>
                        </ul>
                      </div>
                      <div className="col">
                            <span
                              className={`badge rounded-pill bg-light-${value.primary_action === "soft-delete" && 'danger' || value.primary_action === "create" && 'success' || value.primary_action === "update" && 'primary'}`}
                            >
                              {value.primary_action}
                            </span>
                      </div>
                      <div className="col">
                        <a href="#" className="btn btn-primary light">
                          <i className="bi bi-chevron-right"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const mapStateToProps = (state) => {
      return{
          list: state.logs,
          loginData: state.loginData,
      }
  }

  const mapDispatchToProps = (dispatch) => {
      return{
        getList : (tokenData) => dispatch(getList(tokenData))
      }
  }

export default connect(mapStateToProps, mapDispatchToProps)(RecentUpdates)

