import {
  PAGE_LIST_LOADING,
  GET_PAGE_LIST,
  GET_PAGE_LIST_ERROR,
  GET_PAGE_BY_ID_LOADING,
  GET_PAGE_BY_ID_SUCCESS,
  GET_PAGE_BY_ID_ERROR,
  DELETEING_PAGE,
  DELETE_PAGE,
  DELETE_PAGE_ERROR,
  DELETED_PAGE_LIST_LOADING,
  GET_DELETED_PAGE_LIST,
  GET_DELETED_PAGE_LIST_ERROR,
  PERMA_DELETEING_PAGE,
  PERMA_DELETE_PAGE,
  PERMA_DELETE_PAGE_ERROR,
  RESTORE_PAGE_LIST_LOADING,
  RESTORE_PAGE_LIST,
  RESTORE_PAGE_LIST_ERROR
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: PAGE_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/menu/page/active`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_PAGE_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_PAGE_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_PAGE_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/menu/page/active/${id}`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_PAGE_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_PAGE_BY_ID_ERROR,
        payload: error.response,
      });
    });
};



export const getDeletedList = (tokenData) => async (dispatch) => {
  dispatch({
    type: DELETED_PAGE_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/menu/page/deleted`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_DELETED_PAGE_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_DELETED_PAGE_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const deleteEntityById = (idList) => async (dispatch) => {
  dispatch({
    type: DELETEING_PAGE,
  });
  let bulk = {bulk: idList}
  await axios
    .post(`${BASE_URL}api/v1/admin/menu/page/bulk/delete`, bulk, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: DELETE_PAGE,
        payload: response,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: DELETE_PAGE_ERROR,
        payload: error.response,
      })
      message.error(error.response.data.message);
    });
};

export const permanentDeleteEntity = (idList) => async (dispatch) => {
  dispatch({
    type: PERMA_DELETEING_PAGE,
  });
  let bulk = {bulk: idList}
  await axios
    .post(`${BASE_URL}api/v1/admin/menu/page/bulk/perma-delete`, bulk, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: PERMA_DELETE_PAGE,
        payload: response,
      })
      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: PERMA_DELETE_PAGE_ERROR,
        payload: error.response,
      })
      
      message.error(error.response.data.message);
    });
};

export const restoretDeleteEntity = (idList) => async (dispatch) => {
  dispatch({
    type: RESTORE_PAGE_LIST_LOADING,
  });
  let bulk = {bulk: idList}
  await axios
    .post(`${BASE_URL}api/v1/admin/menu/page/bulk/restore`, bulk, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: RESTORE_PAGE_LIST,
        payload: response,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: RESTORE_PAGE_LIST_ERROR,
        payload: error.response,
      }) 
      message.error(error.response.data.message);
    });
};