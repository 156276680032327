import React from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { permanentDeleteEntity, getDeletedList, restoretDeleteEntity} from "../../Redux/Category/actions";

function DeletedList({ useAuth, getList, list, onDelete, onRestore}) {
  const tableConfig = {
    title: "Deleted Categorie(s)",
    applyFilter: false,
    tableColumns: [
      {name: "Title", selector: 'title', width: '77%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'title',
    searchByColumn: 'title',
    //updatePath: "/category/update",    
    addPath: "/category/add",
    restorePath: "/categories",
    noDataMessage: "No categories exists",              
    //updateLinkColumn: 'title',
    deleteBtnText: 'Permanent Delete',
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList)
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}


const mapStateToProps = (state) => {
  return {
    list: state.category,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getDeletedList(tokenData)),
    onDelete: (idList) => dispatch(permanentDeleteEntity(idList)),
    onRestore: (idList) => dispatch(restoretDeleteEntity(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletedList);
