import React from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { deleteEntityById, getList } from "../../Redux/Blog/actions";
function List({ useAuth, getList, list, onDelete }) {
  const tableConfig = {
    title: "Blogs",
    filterOptionTitle: "Blog Type",
    filterOptions: [
      "admin",
      "superadmin",
      "member",
    ],
    defaultFilterValue: 'admin',
    applyFilter: false,
    tableColumns: [
      {name: "Order", selector: 'order', width: '5%'},
      {name: "Status", selector: 'status', width: '5%'},
      {name: "Title", selector: 'title', width: '70%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'title',
    searchByColumn: 'title',
    updatePath: "/blog/update",    
    addPath: "/blog/add",
    noDataMessage: "No blog exists",
    deleteBtnText: 'Delete',
    updateLinkColumn: 'title',
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
  }

  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}


const mapStateToProps = (state) => {
  return {
    list: state.blog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
