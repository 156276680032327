import {
  GET_USER_LIST_ERROR,
  GET_USER_LIST,
  USER_LIST_LOADING,
  GET_USER_BY_ID_LOADING,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR,
  DELETED_USER_LIST_LOADING,
  GET_DELETED_USER_LIST,
  GET_DELETED_USER_LIST_ERROR,
  DELETEING_USER,
  DELETE_USER,
  DELETE_USER_ERROR,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_USER_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_USER_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_USER_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };

    case DELETE_USER:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_DELETED_USER_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_USER_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_USER_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };

    case GET_DELETED_USER_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
