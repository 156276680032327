export const BLOG_LIST_LOADING = "BLOG_LIST_LOADING";
export const GET_BLOG_LIST = "GET_BLOG_LIST";
export const GET_BLOG_LIST_ERROR = "GET_BLOG_LIST_ERROR";

export const GET_BLOG_BY_ID_LOADING = "GET_BLOG_BY_ID_LOADING";
export const GET_BLOG_BY_ID_SUCCESS = "GET_BLOG_BY_ID_SUCCESS";
export const GET_BLOG_BY_ID_ERROR = "GET_BLOG_BY_ID_ERROR";

export const DELETEING_BLOG = "DELETEING_BLOG";
export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_ERROR = "DELETE_BLOG_ERROR" 

export const DELETED_BLOG_LIST_LOADING = "DELETED_BLOG_LIST_LOADING";
export const GET_DELETED_BLOG_LIST = "GET_DELETED_BLOG_LIST";
export const GET_DELETED_BLOG_LIST_ERROR = "GET_DELETED_BLOG_LIST_ERROR";

export const RESTORE_BLOG_LIST_LOADING = "RESTORE_BLOG_LIST_LOADING";
export const RESTORE_BLOG_LIST = "RESTORE_BLOG_LIST";
export const RESTORE_BLOG_LIST_ERROR = "RESTORE_BLOG_LIST_ERROR";

export const PERMA_DELETEING_BLOG = "PERMA_DELETEING_BLOG";
export const PERMA_DELETE_BLOG = "PERMA_DELETE_BLOG";
export const PERMA_DELETE_BLOG_ERROR = "PERMA_DELETE_BLOG_ERROR" 
