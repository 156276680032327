import { 
  CREATING_MENUTYPE,
  UPDATING_MENUTYPE,
  MENUTYPE_CREATED,
  MENUTYPE_UPDATED,
  ERROR_MENUTYPE_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
}

export const createEntity = (formData, tokenData, exitNow, history) => async (dispatch) => {
  dispatch({
    type: CREATING_MENUTYPE,
  });

  let webKitFormData = new FormData();
  Object.keys(formData).forEach((item, i) => {
    webKitFormData.append(item, formData[item])
  });

  await axios
    .post(`${BASE_URL}api/v1/admin/menu/type/add`, webKitFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      console.log('response::::::', response);
      dispatch({
        type: MENUTYPE_CREATED,
        payload: response,
      });
      dispatch({
        type: UPDATED_RECENTLY,
      });
      if (exitNow) {
        history.push("/menus")
      }      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: ERROR_MENUTYPE_CREATE,
        payload: error.response,
      })      
      message.error(error.response.data.message);
    });
};

export const updateEntityInfo = (formData, tokenData, id) => async (dispatch) => {
  dispatch({
    type: UPDATING_MENUTYPE,
  });

  let webKitFormData = new FormData();
  Object.keys(formData).forEach((item, i) => {
    webKitFormData.append(item, formData[item])
  });  
  webKitFormData.append('_method', 'put')

  await axios
    .post(`${BASE_URL}api/v1/admin/menu/type/edit/${id}`,  webKitFormData, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: MENUTYPE_UPDATED,
        payload: response,
      });
      dispatch({
        type: UPDATED_RECENTLY,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: ERROR_MENUTYPE_CREATE,
        payload: error.response,
      })
      message.error(error.response.data.message);
    });
};
