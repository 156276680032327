export const CATEGORY_LIST_LOADING = "CATEGORY_LIST_LOADING";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_ERROR = "GET_CATEGORY_LIST_ERROR";
export const GET_CATEGORY_BY_ID_LOADING = "GET_CATEGORY_BY_ID_LOADING";
export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS";
export const GET_CATEGORY_BY_ID_ERROR = "GET_CATEGORY_BY_ID_ERROR";

export const DELETEING_BLOG_CATEGORY = "DELETEING_BLOG_CATEGORY";
export const DELETE_BLOG_CATEGORY = "DELETE_BLOG_CATEGORY";
export const DELETE_BLOG_CATEGORY_ERROR = "DELETE_BLOG_CATEGORY_ERROR" 

export const DELETED_BLOG_CATEGORY_LIST_LOADING = "DELETED_BLOG_CATEGORY_LIST_LOADING";
export const GET_DELETED_BLOG_CATEGORY_LIST = "GET_DELETED_BLOG_CATEGORY_LIST";
export const GET_DELETED_BLOG_CATEGORY_LIST_ERROR = "GET_DELETED_BLOG_CATEGORY_LIST_ERROR";

export const RESTORE_BLOG_CATEGORY_LIST_LOADING = "RESTORE_BLOG_CATEGORY_LIST_LOADING";
export const RESTORE_BLOG_CATEGORY_LIST = "RESTORE_BLOG_CATEGORY_LIST";
export const RESTORE_BLOG_CATEGORY_LIST_ERROR = "RESTORE_BLOG_CATEGORY_LIST_ERROR";

export const PERMA_DELETEING_BLOG_CATEGORY = "PERMA_DELETEING_BLOG_CATEGORY";
export const PERMA_DELETE_BLOG_CATEGORY = "PERMA_DELETE_BLOG_CATEGORY";
export const PERMA_DELETE_BLOG_CATEGORY_ERROR = "PERMA_DELETE_BLOG_CATEGORY_ERROR" 
