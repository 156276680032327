import React from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList, deleteEntityById } from "../../Redux/Testimonial/actions";

function List({ useAuth, getList, list, onDelete}) {
  const tableConfig = {
    title: "Testimonials",
    filterOptionTitle: "Category Type",
    filterOptions: [
      "category",
    ],
    defaultFilterValue: 'admin',
    applyFilter: false,
    tableColumns: [
      {name: "Order", selector: 'order', width: '3%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Name", selector: 'full_name', width: '70%'},
      {name: "Modified Date", selector: 'updated_at', width: '17%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'full_name',
    searchByColumn: 'full_name',
    updatePath: "/testimonial/update", 
    addPath: "/testimonial/add",
    noDataMessage: "No testimonials exists",
    updateLinkColumn: 'full_name',
    deleteBtnText: 'Delete',
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
  }

  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.testimonial,  
    loginData: state.loginData, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
