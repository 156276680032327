import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList as getCategoryList, deleteEntityById } from "../../Redux/Category/actions";
import { getList as getCategoryTypeList } from "../../Redux/CategoryType/actions";

function List({ useAuth, loginData, getList, list, getTypeList, listType, onDelete }) {
  let tokenData = loginData.data;
  const [filter_options, setFilterOptions] = useState();

  useEffect(() => {
    if (!listType.data) {
      getTypeList(tokenData);
    }
    if(listType.data){
       setFilterOptions(listType.data.data.map(e=>{return{value:e.id, text:e.category_type}}))
    }

  }, [list.data, listType.data]);


list.data?.data.map(a => {
  if(a.children?.length === 0){
    delete a.children
  }
  if (a.children){
    a.children.map(b => {
      if(b.children?.length === 0){
        delete b.children
      }
    })
  }
})  

  const tableConfig = {
    title: "Categories",
    filterOptionTitle: "Category Type",
    filterOptions: filter_options,
    applyFilter: true,
    tableColumns: [
      {name: "Title", selector: 'title', width: '77%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    isChildren: true,
    filterByColumn: 'type_id',
    searchByColumn: 'title',   
    addPath: "/category/add",
    updatePath: "/category/update",
    addTypePath: "/category-type/add",
    addTypeText: "Add Category Type",
    noDataMessage: "No category exists",
    deleteBtnText: 'Delete',
    updateLinkColumn: 'title',
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.category,
    listType: state.categoryType,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getCategoryList(tokenData)),
    getTypeList: (tokenData) => dispatch(getCategoryTypeList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
