import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardLayout from "../../Components/Layouts/DashboardLayout";
import { getList, deleteEntityById } from "../../Redux/MenuType/actions";
import { Link } from "react-router-dom";

import Moment from "react-moment";

function Menu({ useAuth, getList, list, onDelete, loginData }) {
  const tokenData = loginData.data;
  const [listData, setListData] = useState([]);
  useEffect(() => {
    if (!list.data) {
      getList(tokenData);
    }
    if (list.data) {
      setListData(list.data.data);
    }
  }, [list.data]);

  return (
    <DashboardLayout useAuth={useAuth}>
      <div className="page-content">
        <div className="container-fluid">
          <div className="common-module">
            <div className="action-bar">
              <div className="title">
                <h1>Manage Menu</h1>
              </div>
              <div className="action-slot">
                <ul>
                  <li>
                    <Link
                      to="/menu-type/add"
                      className="btn btn-primary"
                      id="add_new"
                    >
                      <i className="bi-plus-circle"></i> Add New
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="common-module mb-2">
            <div className="settings-list">
              {list.loading && (
                <div className="text-center">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </div>
              )}
              <ul>
                {listData && listData.length >= 1 ? (
                  listData.map((itm, idx) => {
                    return (
                      <li key={idx}>
                        <div className="col-9">
                          <h4> 
                            {" "}
                            <Link to={`/menu-type/update/${itm.id}`}>
                              {itm.type_title}
                            </Link>
                            <span className="badge bg-light-success rounded-pill mx-2">
                              {itm.children ? itm.children.length : 0}
                            </span>
                          </h4>
                        </div>
                        <div className="col-1 text-right">
                          <span className="badge bg-light-warning rounded-pill">
                            <Moment fromNow ago>
                              {itm.updated_at}
                            </Moment>{" "}
                            &nbsp;ago
                          </span>
                        </div>
                        <div className="col-2 action">
                          <Link
                            to={`/menu-type/update/${itm.id}`}
                            type="button"
                            className="btn btn-link edit"
                          >
                            <i className="bi-pencil-square"></i>
                          </Link>
                          <button
                            type="button"
                            className="btn btn-link delete"
                            onClick={() => onDelete(itm.id, tokenData)}
                          >
                            <i className="bi-trash"></i>
                          </button>
                        </div>
                      </li>
                    );
                  })
                ) : list.loading === false ? (
                  <div className="px-3">No data exists!</div>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    list: state.menuType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    onDelete: (id, tokenData) => dispatch(deleteEntityById(id, tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
