import {
  LOGS_LIST_LOADING,
  GET_LOGS_LIST,
  GET_LOGS_LIST_ERROR,
  GET_LOGS_BY_ID_LOADING,
  GET_LOGS_BY_ID_SUCCESS,
  GET_LOGS_BY_ID_ERROR,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";

export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: LOGS_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/logs/all`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_LOGS_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_LOGS_LIST_ERROR,
        payload: error.response,
      });
    });
};

//http://flamingo-base/api/v1/admin/logs/user

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_LOGS_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/member/${id}`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_LOGS_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_LOGS_BY_ID_ERROR,
        payload: error.response,
      });
    });
};
