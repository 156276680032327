import React from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getDeletedList, deleteEntityById, restoretDeleteEntity} from "../../Redux/CategoryType/actions";

function DeletedCategoryType({ useAuth, getList, list, onDelete, onRestore }) {
  const tableConfig = {
    title: "Deleted Category Type",
    filterOptionTitle: "Category Type",
    filterOptions: [
      "admin",
      "superadmin",
      "member",
    ],
    defaultFilterValue: 'admin',
    applyFilter: false,
    tableColumns: [
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Title", selector: 'category_type', width: '77%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'type',
    searchByColumn: 'title',   
    addPath: "/category-type/add",
    //updatePath: "/category-type/update",
    restorePath: "/manage-categories",
    noDataMessage: "No category type exists",
    deleteBtnText: 'Delete',
    //updateLinkColumn: 'title',
    deleteBtnText: 'Permanent Delete',
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList)
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.categoryType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getDeletedList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList)),
    onRestore: (idList) => dispatch(restoretDeleteEntity(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletedCategoryType);
