import {
  GET_LOGS_LIST_ERROR,
  GET_LOGS_LIST,
  LOGS_LIST_LOADING,
  GET_LOGS_BY_ID_LOADING,
  GET_LOGS_BY_ID_SUCCESS,
  GET_LOGS_BY_ID_ERROR,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_LOGS_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_LOGS_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_LOGS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };

    case GET_LOGS_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_LOGS_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
