import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import DashboardList from "../../Components/Layouts/DashboardList";
import {getDeletedList, permanentDeleteEntity, restoretDeleteEntity} from "../../Redux/Menu/actions";

function DeletedList({ useAuth, getList, list, onDelete, onRestore}) {
  const location = useLocation();
  const tableConfig = {
    title: "Deleted Menu(s)",
    applyFilter: false,
    tableColumns: [
      {name: "Order", selector: 'order', width: '3%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Title", selector: 'item_title', width: '74%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'type_id',
    searchByColumn: 'item_title',
    addPath: "/menu/add",
    updatePath: "/menu/update",
    //addTypePath: "/menu-type/add",
    addTypeText: "Add Menu Type",
    noDataMessage: "No menu exists",
    updateLinkColumn: 'item_title',
    deleteBtnText: 'Permanent Delete',
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList)
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}



const mapStateToProps = (state) => {
  return {
    list: state.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getDeletedList(tokenData)),
    onDelete: (idList) => dispatch(permanentDeleteEntity(idList)),
    onRestore: (idList) => dispatch(restoretDeleteEntity(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletedList);
