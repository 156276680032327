export const PACKAGE_LIST_LOADING = "PACKAGE_LIST_LOADING";
export const GET_PACKAGE_LIST = "GET_PACKAGE_LIST";
export const GET_PACKAGE_LIST_ERROR = "GET_PACKAGE_LIST_ERROR";

export const GET_PACKAGE_BY_ID_LOADING = "GET_PACKAGE_BY_ID_LOADING";
export const GET_PACKAGE_BY_ID_SUCCESS = "GET_PACKAGE_BY_ID_SUCCESS";
export const GET_PACKAGE_BY_ID_ERROR = "GET_PACKAGE_BY_ID_ERROR";

export const DELETEING_PACKAGE = "DELETEING_PACKAGE";
export const DELETE_PACKAGE = "DELETE_PACKAGE";
export const DELETE_PACKAGE_ERROR = "DELETE_PACKAGE_ERROR" 

export const DELETED_PACKAGE_LIST_LOADING = "DELETED_PACKAGE_LIST_LOADING";
export const GET_DELETED_PACKAGE_LIST = "GET_DELETED_PACKAGE_LIST";
export const GET_DELETED_PACKAGE_LIST_ERROR = "GET_DELETED_PACKAGE_LIST_ERROR";

export const RESTORE_PACKAGE_LIST_LOADING = "RESTORE_PACKAGE_LIST_LOADING";
export const RESTORE_PACKAGE_LIST = "RESTORE_PACKAGE_LIST";
export const RESTORE_PACKAGE_LIST_ERROR = "RESTORE_PACKAGE_LIST_ERROR";

export const PERMA_DELETEING_PACKAGE = "PERMA_DELETEING_PACKAGE";
export const PERMA_DELETE_PACKAGE = "PERMA_DELETE_PACKAGE";
export const PERMA_DELETE_PACKAGE_ERROR = "PERMA_DELETE_PACKAGE_ERROR" 
