import {
  CREATING_MENU,
  UPDATING_MENU,
  MENU_CREATED,
  MENU_UPDATED,
  ERROR_MENU_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
};

export const createEntity =
  (formData, tokenData, exitNow, history) => async (dispatch) => {
    dispatch({
      type: CREATING_MENU,
    });

    let webKitFormData = new FormData();
    Object.keys(formData).forEach((item, i) => {
      webKitFormData.append(item, formData[item]);
    });

    await axios
      .post(`${BASE_URL}api/v1/admin/menu/add`, webKitFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: MENU_CREATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        })        
       message.success(response.data.message);
        if (exitNow) {
          history.push("/menus");
        }
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_MENU_CREATE,
          payload: error.response,
        })        
      message.error(error.response.data.message);
      });
  };

export const updateEntityInfo =
  (formData, tokenData, id) => async (dispatch) => {
    dispatch({
      type: UPDATING_MENU,
    });

    let webKitFormData = new FormData();
    Object.keys(formData).forEach((item, i) => {
      webKitFormData.append(item, formData[item]);
    });
    webKitFormData.append("_method", "put");

    await axios
      .post(`${BASE_URL}api/v1/admin/menu/edit/${id}`, webKitFormData, {
        headers: {
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: MENU_UPDATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        })        
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_MENU_CREATE,
          payload: error.response,
        })        
       message.error(error.response.data.message);
      });
  };
