import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Switch,
  Alert,
  Rate,
  DatePicker,
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";
import ImageField from "../Core/ImageField";
import moment from "moment";

import { getList as packageList } from "../../Redux/Package/actions";

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import DashboardActionBar from "../DashboardActionBar";

const { Option } = Select;

const TestimonialForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getPackageList,
  packageListData,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [review, setReview] = useState(
    isUpdateForm && data.dataById?.data.review
  );
  const [avatar, setAvatar] = useState(
    isUpdateForm
      ? data.dataById &&
          data.dataById.data.avatar &&
          data.dataById.data.avatar.full_path
      : null
  );
  const [ratingValue, setRatingValue] = useState(!isUpdateForm && 0);
  const [image_crop_open, setImageCropOpen] = useState(false);

  const [form] = Form.useForm();
  const { validateFields, setFieldsValue, getFieldValue } = form;
  const loading = selectedForm.loading || packageListData.loading; // || data.loading

  const loadPackages = async () => {
    await getPackageList(tokenData);
  };

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  const countryList = {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas (the)",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia (Plurinational State of)",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory (the)",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    CV: "Cabo Verde",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    KY: "Cayman Islands (the)",
    CF: "Central African Republic (the)",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands (the)",
    CO: "Colombia",
    KM: "Comoros (the)",
    CD: "Congo (the Democratic Republic of the)",
    CG: "Congo (the)",
    CK: "Cook Islands (the)",
    CR: "Costa Rica",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czechia",
    CI: "Côte d'Ivoire",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic (the)",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    SZ: "Eswatini",
    ET: "Ethiopia",
    FK: "Falkland Islands (the) [Malvinas]",
    FO: "Faroe Islands (the)",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories (the)",
    GA: "Gabon",
    GM: "Gambia (the)",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See (the)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran (Islamic Republic of)",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea (the Democratic People's Republic of)",
    KR: "Korea (the Republic of)",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic (the)",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands (the)",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia (Federated States of)",
    MD: "Moldova (the Republic of)",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands (the)",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger (the)",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands (the)",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestine, State of",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines (the)",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    MK: "Republic of North Macedonia",
    RO: "Romania",
    RU: "Russian Federation (the)",
    RW: "Rwanda",
    RE: "Réunion",
    BL: "Saint Barthélemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan (the)",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands (the)",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates (the)",
    GB: "United Kingdom of Great Britain and Northern Ireland (the)",
    UM: "United States Minor Outlying Islands (the)",
    US: "United States of America (the)",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela (Bolivarian Republic of)",
    VN: "Viet Nam",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    AX: "Åland Islands",
  };

  const countries = Object.entries(countryList).map(([key, value], i) => {
    return (
      <Option key={i} value={key}>
        {value}
      </Option>
    );
  });

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!packageListData.data) {
      loadPackages();
    }
  }, [packageListData.data]);

  const packageListOptions = packageListData.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.package_title,
    };
  });

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };


  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const axios = require("axios");
      values.review = review
      values.review_rating = ratingValue;
      values.review_date = formatDate(values.review_date);
      values.is_featured = values.is_featured ? 1 : 0;
      values.status = values.status ? 1 : 0;

      if (action === "add") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity(
              {
                ...values,
                avatar: response.data,
              },
              tokenData
            )
          });
        } else {
          createEntity(
            {
              ...values,
            },
            tokenData
          )
        }
      } else if (action === "addandexit") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity(
              {
                ...values,
                avatar: response.data,
              },
              tokenData,
              true,
              history
            )
          });
        } else {
          createEntity(
            {
              ...values,
            },
            tokenData,
            true,
            history
          )
        }
      } else if (action === "update") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            updateEntityInfo(
              {
                ...values,
                avatar: response.data,
              },
              tokenData,
              params.id
            ).then(() => {
              loadEntityById();
            });
          });
        } else {
          updateEntityInfo(
            {
              ...values,
            },
            tokenData,
            params.id
          ).then(() => {
            loadEntityById();
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setReview("");
    setAvatar("");
  };

  if (isUpdateForm) {
    setFieldsValue({
      salutation: data.dataById?.data.salutation,
      full_name: data.dataById?.data.full_name,
      url_title: data.dataById?.data.urlinfo?.url_title,
      slug: data.dataById?.data.urlinfo?.url_slug,
      package_id: data.dataById?.data.package_id,
      review_date: data.dataById?.data.review_date,
      review_rating: data.dataById?.data.review_rating,
      reivew_abstract: data.dataById?.data.reivew_abstract,
      affiliate_organization: data.dataById?.data.affiliate_organization,
      organization_address: data.dataById?.data.organization_address,
      organization_position: data.dataById?.data.organization_position,
      organization_link: data.dataById?.data.organization_link,
      status: data.dataById?.data.status == 1 ? true : false,
      is_featured: data.dataById?.data.is_featured == 1 ? true : false,
      review: data.dataById?.data.review,
      meta_title: data.dataById?.data.meta?.meta_title,
      meta_keywords: data.dataById?.data.meta?.meta_keywords,
      meta_review: data.dataById?.data.meta?.meta_review,
      avatar: data.dataById?.data.avatar?.thumb_path,
      avatar_alt: data.dataById?.data.avatar?.avatar_alt,
      avatar_caption: data.dataById?.data.avatar?.avatar_caption,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Testimonial",
    titleToUpdate: "Update Testimonial",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Testimonial",
    taskCompleteAddMessage: "Testimonial is added!",
    taskCompleteUpdateMessage: "Testimonial is updated!",
    discardLink: "testimonials",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  const desc = ["Terrible", "Poor", "Average", "Very Good", "Excellent"];

  const handleRatingChange = (value) => {
    console.log(value);
    setRatingValue(value);
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <div className="common-module mt-3 bg-white">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Review Title"
                name="url_title"
                rules={[
                  {
                    required: true,
                    message: "Url title is required",
                  },
                  { min: 5, max: 255, message: "Invalid Url title" },
                ]}
              >
                <Input
                  onChange={(e) =>
                    form.setFieldsValue({ slug: slugify(e.target.value) })
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Row gutter={{ xs: 2.5, sm: 2.5, md: 2.5 }}>
                <Col className="gutter-row" xs={6}>
                  <Form.Item
                    label="Full Name"
                    name="salutation"
                    rules={[
                      {
                        required: true,
                        message: "Salutation is required",
                      },
                    ]}
                  >
                    <Select>
                      <Option value="Mr.">Mr.</Option>
                      <Option value="Ms.">Ms.</Option>
                      <Option value="Mrs.">Mrs.</Option>
                      <Option value="Miss.">Miss.</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={18}>
                  <Form.Item
                    label="&nbsp;"
                    name="full_name"
                    className="noStar"
                    rules={[
                      {
                        required: true,
                        message: "Full name is required",
                      },
                      { min: 5, max: 255, message: "Invalid title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Row gutter={{ xs: 2.5, sm: 2.5, md: 2.5 }}>
                <Col className="gutter-row" xs={10}>
                  <Form.Item
                    label="Address"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Select your country",
                      },
                    ]}
                  >
                    <Select placeholder="Country" showSearch>
                      {countries}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={14}>
                  <Form.Item
                    label="&nbsp;"
                    name="address"
                    className="noStar"
                    rules={[
                      {
                        required: true,
                        message: "Address is required",
                      },
                    ]}
                  >
                    <Input placeholder="city, state, zip" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8} hidden>
              <Form.Item
                label={
                  <span className="ant-form-item-optional">
                    Auto Generate from Url Title
                  </span>
                }
                name="slug"
                title="Auto Generate from Url Title"
                rules={[
                  {
                    required: true,
                    message: "Slug is required",
                  },
                ]}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item label="Abstract" name="reivew_abstract">
                <Input />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item label="Select a packages" name="package_id">
                <Select mode="multiple">
                  {packageListOptions?.map((itm, idx) => {
                    return (
                      <Option value={itm.id} key={idx}>
                        {itm.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Date"
                name="review_date"
                rules={[
                  {
                    required: true,
                    message: "Date is required",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item label="Company" name="affiliate_organization">
                <Input />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item label="Company Address" name="organization_address">
                <Input />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item label="Designation" name="organization_position">
                <Input />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Website"
                name="organization_link"
                rules={[{ type: "url", message: "Invalid url" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={16}>
              <div className="switch-group">
                <Form.Item label="Ratings" name="review_rating">
                  <span>
                    <Rate
                      tooltips={desc}
                      onChange={handleRatingChange}
                      value={ratingValue}
                    />
                    {ratingValue ? (
                      <span className="ant-rate-text">
                        {desc[ratingValue - 1]}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </Form.Item>
                <Form.Item label="Status" valuePropName="checked" name="status">
                  <Switch
                    checkedChildren="Publish"
                    unCheckedChildren="Unpublish"
                  />
                </Form.Item>
                <Form.Item
                  label="Is Featured?"
                  valuePropName="checked"
                  name="is_featured"
                >
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
        <div className="common-module pt-0">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24} xl={16}>
              <Form.Item label="Description" name="review">
                <TextEditor
                  value={review}
                  initialValue={isUpdateForm && review}
                  onEditorChange={(e) => {
                    setReview(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={8}>
              <label className="form-label">Meta</label>
              <div className="card bg-white mb-4 mb-xl-0">
                <div className="card-body">
                  <Form.Item
                    label="Meta Title"
                    name="meta_title"
                    rules={[
                      {
                        required: true,
                        message: "Meta title is required",
                      },
                      { min: 5, max: 255, message: "Invalid meta title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Meta Keywords"
                    name="meta_keywords"
                    rules={[
                      {
                        min: 5,
                        max: 255,
                        message: "Invalid meta keywords",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    className="mb-0"
                    label="Meta Description"
                    name="meta_review"
                  >
                    <TextArea rows={8} />
                  </Form.Item>
                </div>
              </div>
            </Col>

            <Col className="gutter-row" xs={24} xl={10}>
              <div className="card bg-white mb-4 mb-xl-0">
                <div className="card-body">
                  <Form.Item>
                    <ImageField
                      title="Avatar"
                      image={
                        avatar ||
                        (isUpdateForm && data.dataById?.data.avatar?.full_path)
                      }
                      height="300"
                      width="400"
                      setImage={(image_data) => {
                        setAvatar(image_data);
                        setImageCropOpen(true);
                      }}
                    />
                    {avatar && image_crop_open && (
                      <ImageCrop
                        image={avatar}
                        setCroppedImage={setAvatar}
                        setImageCropOpen={setImageCropOpen}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: "-20px" }}
                    label="Alt Text"
                    name="avatar_alt"
                    rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Caption"
                    name="avatar_caption"
                    rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    packageListData: state.packages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageList: (tokenData) => dispatch(packageList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialForm);
