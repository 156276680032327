import React, { useState } from "react";

export const InputField = (props) => {
  return(
    <input
      className={props.className || "form-control"}
      {...props}
    />)
}

export const FormTextField = (props) => {
  return(
    <>
      <label for={props.id} className="form-label">
        {props.title}
      </label>
      <textarea
        {...props}
        className="form-control"
      />
    </>
  )
}

export const FormInputField = (props) => {
  return(
    <>
      <label for={props.id} className="form-label">
        {props.title}
      </label>
      <InputField
        {...props}
        className="form-control"
      />
    </>
  )
}

export const PasswordInputField = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return(
    <div className="input-group">
      <InputField {...props} placeholder="Password*" className="form-control input-password" type={passwordShown ? "text" : "password"} />
      <i
        className="bi-eye-slash password-toggler"
        onClick={togglePasswordVisiblity}
      ></i>
    </div>
    )
}

export const EmailInputField = (props) => {
  return(
    <div className="input-group">
      <InputField {...props} className="form-control" type="email" placeholder="Email Address*" autocomplete="on"/>
      <i className="bi-envelope"></i>
    </div>
  )
}

export const SwitchInput = (props) => {
  return(
    <div className="switch-input">
      <input
        className="check-input"
        type="checkbox"
        id="remember_me"
        {...props}
      />
      <label className="check-label" for="remember_me">
        Remember me
      </label>
    </div>
  )
}

export default InputField
