import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Switch,
  Alert,
  message,
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";
import ImageField from "../Core/ImageField";

import { getList as categoryList } from "../../Redux/Category/actions";

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import DashboardActionBar from "../DashboardActionBar";

const ArticleForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getArticleList,
  categoryListData,
  getArticleTypeList,
  categoryTypeListData,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [description, setDescription] = useState(
    isUpdateForm && data.dataById?.data.description
  );
  const [featured_image, setFeaturedImage] = useState(
    isUpdateForm
      ? data.dataById &&
          data.dataById.data.featured &&
          data.dataById.data.featured.full_path
      : null
  );
  const [formHidden, setFormHidden] = useState();
  const [image_crop_open, setImageCropOpen] = useState(false);

  const [form] = Form.useForm();
  const { validateFields, setFieldsValue, getFieldValue } = form;
  const loading = selectedForm.loading || categoryListData.loading; // || data.loading

  const loadArticle = async () => {
    await getArticleList(tokenData);
  };

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!categoryListData.data) {
      loadArticle();
    }
  }, [categoryListData.data]);

  const categoryListOptions = categoryListData.data?.data
    .filter((itm) => itm.type.category_key === "article")
    .map((itm) => {
      return {
        value: itm.id,
        label: itm.title,
        ...(itm.children?.length !== 0 && {
          children: itm.children?.map((a) => {
            return {
              value: a.id,
              label: a.title,
              ...(a.children?.length !== 0 && {
                children: a.children?.map((b) => {
                  return {
                    value: b.id,
                    label: b.title,
                  };
                }),
              }),
            };
          }),
        }),
      };
    });

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const axios = require("axios");
      if (action === "add") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  publish_status: values.publish_status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  description,
                  featured_image: response.data,
                },
                tokenData
              ).then(() => {
                loadArticle();
              });
            });
        } else {
          createEntity(
            {
              ...values,
              publish_status: values.publish_status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              description,
            },
            tokenData
          ).then(() => {
            loadArticle();
          });
        }
      } else if (action === "addandexit") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  publish_status: values.publish_status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  description,
                  featured_image: response.data,
                },
                tokenData,
                true,
                history
              ).then(() => {
                loadArticle();
              });
            });
        } else {
          createEntity(
            {
              ...values,
              publish_status: values.publish_status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              description,
            },
            tokenData,
            true,
            history
          ).then(() => {
            loadArticle();
          });
        }
      } else if (action === "update") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              updateEntityInfo(
                {
                  ...values,
                  publish_status: values.publish_status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  description,
                  featured_image: response.data,
                },
                tokenData,
                params.id
              ).then(() => {
                loadEntityById();
                loadArticle();
              });
            });
        } else {
          updateEntityInfo(
            {
              ...values,
              publish_status: values.publish_status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              description,
            },
            tokenData,
            params.id
          ).then(() => {
            loadEntityById();
            loadArticle();
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setDescription("");
    setFeaturedImage("");
  };

  if (isUpdateForm) {
    setFieldsValue({
      page_title: data.dataById?.data.page_title,
      page_key: data.dataById?.data.page_key,
      url_title: data.dataById?.data.url_title,
      parent_id: data.dataById?.data.parent_id,
      description: data.dataById?.data.page_description,
      slug: data.dataById?.data.urlinfo?.url_slug,
      publish_status: data.dataById?.data.publish_status == 1 ? true : false,
      is_featured: data.dataById?.data.is_featured == 1 ? true : false,
      meta_title: data.dataById?.data.meta?.meta_title,
      meta_keywords: data.dataById?.data.meta?.meta_keywords,
      meta_description: data.dataById?.data.meta?.meta_description,
      featured_image: data.dataById?.data.featured?.thumb_path,
      featured_alt: data.dataById?.data.featured?.alt_text,
      featured_caption: data.dataById?.data.featured?.caption,
      banner: data.dataById?.data.banner?.banner_path,
      banner_alt: data.dataById?.data.banner?.banner_alt,
      banner_caption: data.dataById?.data.banner?.banner_caption,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Article",
    titleToUpdate: "Update Article",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update Article",
    taskCompleteAddMessage: "Article is added!",
    taskCompleteUpdateMessage: "Article is updated!",
    discardLink: "articles",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <div className="common-module mt-3">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Title"
                name="page_title"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                  },
                  { min: 5, max: 255, message: "Invalid title" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Url Title"
                name="url_title"
                rules={[
                  {
                    required: true,
                    message: "Url title is required",
                  },
                  { min: 5, max: 255, message: "Invalid Url title" },
                ]}
              >
                <Input
                  onChange={(e) =>
                    form.setFieldsValue({ slug: slugify(e.target.value) })
                  }
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Page Key"
                name="page_key"
                rules={[
                  {
                    required: true,
                    message: "key is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={12}>
              <div className="switch-group">
                <Form.Item
                  label="Status"
                  valuePropName="checked"
                  name="publish_status"
                >
                  <Switch
                    checkedChildren="Publish"
                    unCheckedChildren="Unpublish"
                  />
                </Form.Item>
              </div>
            </Col>

            <Col className="gutter-row" xs={24} xl={16}>
              <Form.Item label="Description" name="description">
                <TextEditor
                  value={description}
                  initialValue={isUpdateForm && description}
                  onChange={(e) => {
                    setDescription(e);
                  }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={8}>
              <label className="form-label">Meta</label>
              <div className="card bg-white mb-4 mb-xl-0">
                <div className="card-body">
                  <Form.Item
                    label="Meta Title"
                    name="meta_title"
                    rules={[
                      {
                        required: true,
                        message: "Meta title is required",
                      },
                      { min: 5, max: 255, message: "Invalid meta title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Meta Keywords"
                    name="meta_keywords"
                    rules={[
                      {
                        min: 5,
                        max: 255,
                        message: "Invalid meta keywords",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    className="mb-0"
                    label="Meta Description"
                    name="meta_description"
                  >
                    <TextArea rows={8} />
                  </Form.Item>
                </div>
              </div>
            </Col>

            <Col className="gutter-row" xs={24} xl={10}>
              <div className="card bg-white mb-4 mb-xl-0">
                <div className="card-body">
                  <Form.Item>
                    <ImageField
                      title="Featured Image"
                      image={
                        featured_image ||
                        (isUpdateForm &&
                          data.dataById?.data.featured?.full_path)
                      }
                      height="300"
                      width="400"
                      setImage={(image_data) => {
                        setFeaturedImage(image_data);
                        setImageCropOpen(true);
                      }}
                    />
                    {featured_image && image_crop_open && (
                      <ImageCrop
                        image={featured_image}
                        setCroppedImage={setFeaturedImage}
                        setImageCropOpen={setImageCropOpen}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: "-20px" }}
                    label="Alt Text"
                    name="featured_alt"
                    rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Caption"
                    name="featured_caption"
                    rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col className="gutter-row banner-image" xs={24} xl={14}>
              <Form.List name="banners" className="bg-white">
                {(fields, { add, remove }) => (
                  <>
                    <h6>Banner Images</h6>
                    {fields.map((field, index) => (
                      <div
                        key={field.key}
                        className="card bg-white"
                        align="baseline"
                      >
                        <div class="card-body">
                          <label className="form-label">
                            Banner #{index + 1}{" "}
                            <span
                              class="btn-delate"
                              onClick={() => remove(field.name)}
                            >
                              Delete
                            </span>
                          </label>

                          <Row>
                            <Col xs={24} lg={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "banner_image"]}
                              >
                                <div class="input-group">
                                  <input type="file" />
                                </div>
                              </Form.Item>
                            </Col>

                            <Col xs={24} lg={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "banner_alt"]}
                              >
                                <Input placeholder="Alt Text" />
                              </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                              <Form.Item
                                {...field}
                                name={[field.name, "banner_caption"]}
                              >
                                <Input placeholder="banner caption" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add New Banner
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryListData: state.category,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getArticleList: (tokenData) => dispatch(categoryList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleForm);
