import React from "react";
import KeySearch from "./KeySearch";

export default function NavBarDashboard() {
  return (
    <nav className="top-navbar">
      <div className="container-fluid">
        <div className="navbar-container row">
          <div className="navbar-left col-md-8">
            <KeySearch />
          </div>

          <div className="navbar-right col-md-4">
            <ul className="nav">
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <span className="icon">
                    <i className="bi bi-chat-dots"></i>
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  {" "}
                  <span className="btn btn-primary font-weight-medium rounded-pill">
                    Compose Email
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
