export const MENUTYPE_LIST_LOADING = "MENUTYPE_LIST_LOADING";
export const GET_MENUTYPE_LIST = "GET_MENUTYPE_LIST";
export const GET_MENUTYPE_LIST_ERROR = "GET_MENUTYPE_LIST_ERROR";
export const GET_MENUTYPE_BY_ID_LOADING = "GET_MENUTYPE_BY_ID_LOADING";
export const GET_MENUTYPE_BY_ID_SUCCESS = "GET_MENUTYPE_BY_ID_SUCCESS";
export const GET_MENUTYPE_BY_ID_ERROR = "GET_MENUTYPE_BY_ID_ERROR";

export const DELETEING_MENUTYPE = "DELETEING_MENUTYPE";
export const DELETE_MENUTYPE = "DELETE_MENUTYPE";
export const DELETE_MENUTYPE_ERROR = "DELETE_MENUTYPE_ERROR" 

export const DELETED_MENUTYPE_LIST_LOADING = "DELETED_MENUTYPE_LIST_LOADING";
export const GET_DELETED_MENUTYPE_LIST = "GET_DELETED_MENUTYPE_LIST";
export const GET_DELETED_MENUTYPE_LIST_ERROR = "GET_DELETED_MENUTYPE_LIST_ERROR";

export const RESTORE_MENUTYPE_LIST_LOADING = "RESTORE_MENUTYPE_LIST_LOADING";
export const RESTORE_MENUTYPE_LIST = "RESTORE_MENUTYPE_LIST";
export const RESTORE_MENUTYPE_LIST_ERROR = "RESTORE_MENUTYPE_LIST_ERROR";

export const PERMA_DELETEING_MENUTYPE = "PERMA_DELETEING_MENUTYPE";
export const PERMA_DELETE_MENUTYPE = "PERMA_DELETE_MENUTYPE";
export const PERMA_DELETE_MENUTYPE_ERROR = "PERMA_DELETE_MENUTYPE_ERROR" 
