import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import UserLogPageLayout from "../Components/LogPageLayout";
import LoginForm from "../Components/Forms/LoginForm";
import { loginUser, setUserLoginDataFromLocalStorage } from "../Redux/UserAuth/actions";

function Login({ useAuth, loginUser, loginData, setUserLoginDataFromLocalStorage }) {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/dashboard" } };
  let loginClicked = (userCredentials, remember_me) => {
    loginUser(userCredentials, remember_me);
  };
  if (loginData.logged_in) {
    auth.signin(() => {
      history.replace(from);
    }, loginData);
  } else if (
    localStorage &&
    localStorage.getItem('access_token') &&
    localStorage.getItem('expires_at') &&
    localStorage.getItem('token_type')
  ) {
    setUserLoginDataFromLocalStorage()
  }

  console.log(`Please login to access: ${from.pathname}`);
  if (loginData.logged_in) {
    // REDIRECTING
    return <div></div>;
  }

  return (
    <UserLogPageLayout>
      <LoginForm loginClicked={loginClicked} loginData={loginData} />
    </UserLogPageLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (userCredentials, remember_me) => dispatch(loginUser(userCredentials, remember_me)),
    setUserLoginDataFromLocalStorage: () => dispatch(setUserLoginDataFromLocalStorage())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
